import React from "react";
import { Icon, ListItem, ListItemText } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import { NavLinkAdapter, FuseUtils } from "@fuse";
import { withRouter } from "react-router-dom";
import clsx from "clsx";
import PropTypes from "prop-types";
import { useDispatch, useSelector } from "react-redux";
import * as Actions from "app/store/actions";
import FuseNavBadge from "./../FuseNavBadge";

const useStyles = makeStyles((theme) => ({
  item: {
    height: 40,
    width: "100%",
    paddingRight: "3px",
    "&.active": {
      backgroundColor: "#fff0dd",
      color: theme.palette.secondary.main,
      pointerEvents: "none",
      borderLeft: "3px solid",
      borderColor: theme.palette.secondary.main,
      transition: "0.3s",
      "& .list-item-text-primary": {
        color: "inherit",
      },
      "& .list-item-icon": {
        color: "inherit",
        marginLeft: "-3px",
      },
    },
    "&.square, &.active.square": {
      width: "100%",
      borderRadius: "0",
    },
    "& .list-item-icon": { color: "rgba(237, 113, 34, 0.7)" },
    "& .list-item-text": {},
    color: theme.palette.text.primary,
    cursor: "pointer",
    textDecoration: "none!important",
  },
}));

function FuseNavVerticalItem(props) {
  const dispatch = useDispatch();
  const userRole = useSelector(({ auth }) => auth.user.role);

  const classes = useStyles(props);
  const { item, nestedLevel, active } = props;
  let paddingValue = 5 + nestedLevel * 16;
  const listItemPadding =
    nestedLevel > 0 ? "pl-" + (paddingValue > 10 ? 10 : paddingValue) : "pl-5";

  if (!FuseUtils.hasPermission(item.auth, userRole)) {
    return null;
  }

  return (
    <ListItem
      button
      component={NavLinkAdapter}
      to={item.url}
      activeClassName="active"
      className={clsx(classes.item, listItemPadding, "list-item", active)}
      onClick={(ev) => dispatch(Actions.navbarCloseMobile())}
      exact={item.exact}
    >
      {item.icon && (
        <Icon
          className="list-item-icon text-25 flex-shrink-0 mr-16"
          color="action"
        >
          {item.icon}
        </Icon>
      )}
      <ListItemText
        className="list-item-text"
        primary={item.title}
        classes={{
          primary: "list-item-text-primary colorTextDashboard",
        }}
      />
      {item.badge && <FuseNavBadge badge={item.badge} />}
    </ListItem>
  );
}

FuseNavVerticalItem.propTypes = {
  item: PropTypes.shape({
    id: PropTypes.string.isRequired,
    title: PropTypes.string,
    icon: PropTypes.string,
    url: PropTypes.string,
  }),
};

FuseNavVerticalItem.defaultProps = {};

const NavVerticalItem = withRouter(React.memo(FuseNavVerticalItem));

export default NavVerticalItem;
