import promise from "promise";
import ApiService from "@core/services/api/api.service";

export default class AkilcabService {
  constructor() {
    this._api = new ApiService();
    this.endpoint = "akilcab";
  }

  /**
   * Get all applications list
   * @param params
   */
  getApplications(params) {
    return this._api
      ._get(`${this.endpoint}/applications`, params)
      .then((response) => response.data)
      .then((response) => response.data)
      .catch((error) => promise.reject(error));
  }

  /**
   * Get all countries
   */
  getCountries() {
    return this._api
      ._get(`${this.endpoint}/countries`)
      .then((response) => response.data)
      .then((response) => response.data)
      .catch((error) => promise.reject(error));
  }

  /**
   * Get all companies types
   */
  getCompanyTypes() {
    return this._api
      ._get(`${this.endpoint}/company-types`)
      .then((response) => response.data)
      .then((response) => response.data)
      .catch((error) => promise.reject(error));
  }

  /**
   * Get Accountant systems
   */
  getAccountantSystems() {
    return this._api
      ._get(`${this.endpoint}/accountant-systems`)
      .then((response) => response.data)
      .then((response) => response.data)
      .catch((error) => promise.reject(error));
  }

  /**
   * Get activities by sector
   * @param sectorId
   */
  getActivities(sectorId) {
    return this._api
      ._get(`${this.endpoint}/accountant-activities/sectors/${sectorId}`)
      .then((response) => response.data)
      .then((response) => response.data)
      .catch((error) => promise.reject(error));
  }

  /**
   * Get accountant activities by country
   * @param countrId
   */
  getAccountantActivities(countryId) {
    return this._api
      ._get(
        `${this.endpoint}/countries/${countryId}/accountant-activities/sectors`
      )
      .then((response) => response.data)
      .then((response) => response.data)
      .catch((error) => promise.reject(error));
  }

  /**
   * Get taxt systems by country
   * @param countries
   */
  getTaxSystems(countryId) {
    return this._api
      ._get(`${this.endpoint}/countries/${countryId}/tax-systems`)
      .then((response) => response.data)
      .then((response) => response.data)
      .catch((error) => promise.reject(error));
  }

  /**
   * Get regional directions by country
   * @param countrId
   */
  getRegionalDirections(countryId) {
    return this._api
      ._get(`${this.endpoint}/countries/${countryId}/regional-directions`)
      .then((response) => response.data)
      .then((response) => response.data)
      .catch((error) => promise.reject(error));
  }

  /**
   * Get tax servicex by regional direction
   * @param regional_direction_id
   */
  getTaxServices(regional_direction_id) {
    return this._api
      ._get(
        `${this.endpoint}/regional-directions/${regional_direction_id}/tax-services`
      )
      .then((response) => response.data)
      .then((response) => response.data)
      .catch((error) => promise.reject(error));
  }

  /**
   * Get banks by country
   * @param countrId
   */
  getBanks(countryId) {
    return this._api
      ._get(`${this.endpoint}/countries/${countryId}/banks`)
      .then((response) => response.data)
      .then((response) => response.data)
      .catch((error) => promise.reject(error));
  }
}
