import * as Actions from "../../actions/fuse/index";
import { navigationConfig } from "app/fuse-configs/navigationConfig";
// import { clientNavigationConfig } from "app/fuse-configs/navigationConfig";
// import authService from "app/services/authService/authService";

const initialState = navigationConfig;

const navigation = function (state = initialState, action) {
  // setTimeout(() => {
  // authService.init();
  // const _credentials = authService.credentials();
  // console.log('REDUCERS _CREDENTIALS ', _credentials);
  // console.log('REDUCERS _CREDENTIALS activeStructure ', _credentials.activeStructure);
  // if (_credentials.activeStructure && !_credentials.activeStructure.is_cabinet) {
  //   state = clientNavigationConfig;
  // }

  // if (_credentials.activeStructure && !_credentials.activeStructure.is_cabinet) {
  //   console.log('log');
  //   state = clientNavigationConfig;
  // }

  // if (is_cabinet) {
  //   this.menu = [...MENU_ITEMS];
  //   this.routeParams = params;
  //   this.menuEtatFinancier = getMenu(this.routeParams, this.isAuditor);
  // } else {
  //   this.menu = [...MENU_CLIENT_ITEMS];
  //   this.routeParams = params;
  //   this.menuEtatFinancier = getMenuClientEtatFinancier(this.routeParams);
  // }

  switch (action.type) {
    case Actions.GET_NAVIGATION: {
      return [...state];
    }
    case Actions.SET_NAVIGATION: {
      return [...action.navigation];
    }
    case Actions.RESET_NAVIGATION: {
      return [...initialState];
    }
    default: {
      return state;
    }
  }
  // }, 5000);
};

export default navigation;
