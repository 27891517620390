import React from "react";
import { FuseLayout, FuseTheme } from "@fuse";
import Provider from "react-redux/es/components/Provider";
import { Router } from "react-router-dom";
import jssExtend from "jss-extend";
import history from "@history";
import store from "./store";
import AppContext from "./AppContext";
import routes from "./fuse-configs/routesConfig";
import { create } from "jss";
import {
  StylesProvider,
  jssPreset,
  createGenerateClassName,
} from "@material-ui/styles";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { BrowserRouter, Switch } from "react-router-dom";
import "suneditor/dist/css/suneditor.min.css";
import { UserCredentialsProvider } from "@shared/components/useContext/useContext";

const jss = create({
  ...jssPreset(),
  plugins: [...jssPreset().plugins, jssExtend()],
  insertionPoint: document.getElementById("jss-insertion-point"),
});

const generateClassName = createGenerateClassName();

toast.configure({
  autoClose: 8000,
  draggable: false,
  hideProgressBar: true,
  closeButton: false,
  pauseOnFocusLoss: true,
});

const App = () => {
  return (
    <AppContext.Provider
      value={{
        routes,
      }}
    >
      <StylesProvider jss={jss} generateClassName={generateClassName}>
        <Provider store={store}>
          <UserCredentialsProvider>
            <BrowserRouter>
              <Switch>
                <Router history={history}>
                  <FuseTheme>
                    <FuseLayout />
                  </FuseTheme>
                </Router>
              </Switch>
            </BrowserRouter>
          </UserCredentialsProvider>
        </Provider>
      </StylesProvider>
    </AppContext.Provider>
  );
};

export default App;
