import React from "react";
import dgxBanner from "@shared/assets/images/dgxBanner.jpg";

const BannerOfPresentation = () => {
  return (
    <div className="styleBannerOfPresentation">
      <img src={dgxBanner} alt="banner" />
      <div className="overImgBanner">
        <h1>
          <span>DigitExpert</span>, la solution qui aide le professionnel du
          chiffre à bien planifier l'ensemble de vos missions.
        </h1>
        <p>
          Inspiré du métier de l’expert-comptable, DIGITEXPERT est une
          application collaborative qui permet de gérer toutes les étapes des
          missions de votre cabinet dans le respect des normes ISA
        </p>
        <a
          style={{ textDecoration: "none" }}
          href={`${process.env.REACT_APP_CUSTOM_AKILCAB_URL}/#/landing`}
        >
          <button>Je m'abonne</button>
        </a>
      </div>
    </div>
  );
};

export default BannerOfPresentation;
