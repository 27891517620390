import React, { useState, useEffect } from "react";
import ExerciseService from "@core/services/exercise/exercise.service";
import { toast } from "react-toastify";
import ModaleErrorExercise from "../Modal/ModaleErrorExercise";
import { useUserCredentials } from "@shared/components/useContext/useContext";

const ExerciceSelect = () => {
  const { credentials } = useUserCredentials();
  const exerciseService = new ExerciseService();
  const [exercices, setExercices] = useState([]);
  const [exercice, setExercice] = useState(
    localStorage.getItem("exerciceId") || ""
  );

  const getExercises = React.useCallback(() => {
    exerciseService
      .getExercises()
      .then((res) => {
        setExercices(res.data);
        handleVerifyExistenceOfExercise(res.data);
        handleVerifyLink();
        handleSelectLastYear(res.data);
      })
      .catch(() =>
        toast.warn("Une erreur est survenue lors de l'affichage des exercices")
      );
  }, [credentials]);

  useEffect(() => {
    getExercises();
  }, []);

  const handleChangeYear = (e) => {
    e.preventDefault();
    const idExercice = e.target.value;
    if (idExercice) {
      setExercice(idExercice);
      localStorage.setItem("exerciceId", idExercice);
      window.location.reload();
    }
  };
  const [openModal, setOpenModal] = useState(false);
  const handleVerifyExistenceOfExercise = (exercises) => {
    if (exercises && exercises.length > 0) {
      setOpenModal(false);
      hanldeCloseModal(false);
    } else if (exercises && exercises.length === 0) {
      setOpenModal(true);
      hanldeCloseModal(true);
    }
  };
  const hanldeCloseModal = (open) => {
    setOpenModal(open);
  };
  const handleVerifyLink = () => {
    if (window.location.href.includes("/cabinet/exercices/creation")) {
      hanldeCloseModal(false);
    }
  };
  const handleSelectLastYear = (years) => {
    const length = years?.length;
    const year = years[length - 1];
    setExercice(year.label);
    localStorage.setItem("exerciceId", year.label);
  };

  return (
    <>
      <div className="w-auto h-full flex justify-around ">
        <select
          className="__buttonSimple"
          name="annee"
          key={exercice}
          value={exercice}
          onChange={(e) => handleChangeYear(e)}
        >
          <option>-- Choisir une année --</option>
          {exercices &&
            exercices.length > 0 &&
            exercices.map((exercice, index) => (
              <option
                key={index}
                value={exercice.label}
              >{`Exercice ${exercice.label}`}</option>
            ))}
        </select>
      </div>
      <ModaleErrorExercise
        formErrorMessage={`Vous ne pouvez pas choisir une année. Merci de créer votre tout premier exercice depuis le Menu Exercice`}
        handleClose={hanldeCloseModal}
        openModal={openModal}
        action="NEW_EXERCISE"
      />
    </>
  );
};

export default ExerciceSelect;
