import crossStorageService from "app/services/crossStorageService";

let credentialsKey = "credentials";
let redirectUrl = `${process.env.REACT_APP_CUSTOM_REDIRECTBACK_URL}/landing`;

class authService {
  constructor() {
    this.savedCredentials =
      sessionStorage.getItem(credentialsKey) ||
      localStorage.getItem(credentialsKey);
    if (this.savedCredentials) {
      this._credentials = JSON.parse(this.savedCredentials);
    }
  }

  init() {
    this.savedCredentials =
      sessionStorage.getItem(credentialsKey) ||
      localStorage.getItem(credentialsKey);
    if (this.savedCredentials) {
      this._credentials = JSON.parse(this.savedCredentials);
    }
  }

  /**
   * Checks is the user is authenticated.
   * @return {boolean} True if the user is authenticated.
   */
  authenticated() {
    return !!this._credentials ? true : false;
  }

  /**
   * Gets the user credentials.
   * @return {Credentials} The user credentials or null if the user is not authenticated.
   */
  credentials() {
    return (
      (this._credentials && this._credentials.credentials) ||
      (this._credentials && this._credentials)
    );
  }

  /**
   * Check if user is Accountant
   * @returns {boolean}
   */
  userIsAccountant() {
    const credentials =
      (this._credentials && this._credentials.credentials) ||
      (this._credentials && this._credentials);
    return (
      !!credentials.activeStructure.is_cabinet ||
      !!credentials.structures[0].is_cabinet
    );
  }

  setAuthenticatedUser(credentials) {
    this.setCredentials(credentials);
  }

  getRedirectUrl() {
    window.location.href = redirectUrl;
  }

  checkCredentials() {
    return this.getCredentials()
      .then((res) => {
        return res;
      })
      .then((data) => {
        // if (data && data.credentials && !isTokenExpired(data.credentials.token)) {
        //   this.setLocalCredentials(data);
        //   return true;
        // } else {
        //   this.setCredentials(null);
        //   return false;
        // }
      });
  }

  getCredentials() {
    crossStorageService
      .onConnect()
      .then(function () {
        return crossStorageService.get("akilCabCredentials");
      })
      .then(function (res) {
        return JSON.parse(res);
      });
  }

  /**
   * Logs out the user and clear credentials.
   * @return {Observable<boolean>} True if the user was logged out successfully.
   */
  logout() {
    sessionStorage.removeItem(credentialsKey);
    window.localStorage.clear();
    window.location.href = `${process.env.REACT_APP_CUSTOM_AKILCAB_URL}/#/logout/redirection?return_to=${process.env.REACT_APP_CUSTOM_REDIRECTBACK_URL}/landing`;
  }

  setLocalCredentials(data) {
    if (data && data.credentials) {
      const credentials = data.credentials;
      const localCredentials = credentials;
      this.setCredentials(localCredentials);
    }
  }

  /**
   * Sets the user credentials.
   * The credentials may be persisted across sessions by setting the `remember` parameter to true.
   * Otherwise, the credentials are only persisted for the current session.
   * @param {Credentials=} credentials The user credentials.
   * @param {boolean=} remember True to remember credentials across sessions.
   */

  setCredentials(credentials) {
    this._credentials = credentials || null;

    const parseCredentials =
      credentials && credentials.credentials
        ? credentials.credentials
        : credentials;

    if (parseCredentials) {
      sessionStorage.setItem(credentialsKey, JSON.stringify(parseCredentials));
      localStorage.setItem(credentialsKey, JSON.stringify(parseCredentials));
    } else {
      sessionStorage.removeItem(credentialsKey);
      localStorage.removeItem(credentialsKey);
    }
  }
}

const instance = new authService();

export default instance;
