import { Grid } from "@mui/material";
import React, { useState } from "react";
import LoginAkilcab from "@core/services/login/login-akilcab.service";
import { toast } from "react-toastify";

const ContactsLanding = () => {
  const initialValueForm = {
    name: "",
    lastName: "",
    email: "",
    message: "",
  };
  const akilcab = new LoginAkilcab();
  const [stateValueForm, setStateValueForm] = useState(initialValueForm);

  const handleChangeForm = (e) => {
    e.preventDefault();
    let { name, value } = e.target;
    setStateValueForm({ ...stateValueForm, [name]: value });
  };

  const handleSubmitFormContact = (e) => {
    e.preventDefault();
    const { name, lastName, email, message } = stateValueForm;

    if ((name !== "" || lastName !== "") && email !== "" && message !== "") {
      const dataContact = {
        fullname: `${name} ${lastName}`,
        email: email,
        message: message,
      };

      if (dataContact) {
        akilcab
          .postEmailLanding(dataContact)
          .then(() => setStateValueForm(initialValueForm))
          .catch(() => {
            toast.info("Une erreur est survenue lors de l'envoi du message.");
          });
      }
    }
  };
  const { name, lastName, email, message } = stateValueForm;

  return (
    <div className="styleBannierWhite">
      <div className="contact-content">
        <Grid container spacing={1}>
          <Grid item md={6}>
            <h2>Contactez-nous</h2>
            <p className="my-5">
              N'hésitez pas à nous contacter pour toutes questions
            </p>
            <p className="my-5">
              <span>icon</span> Faya Laurier 9, Cocody Abidjan Côte d'ivoire
            </p>
            <p className="my-5">
              <span>icon</span> Akilcab@Akiltechnologies.com
            </p>
            <p className="my-5">
              <span>icon</span> +225 27 224 274 06
              <p className="px-10">+225 27 225 441 17</p>
              <p className="px-10">+225 05 05 93 11 18</p>
            </p>
          </Grid>
          <Grid item md={6}>
            <form noValidate>
              <div className="contact-form">
                <Grid container spacing={1}>
                  <Grid item md={6}>
                    <input
                      type="text"
                      placeholder="Nom"
                      name="name"
                      value={name}
                      onChange={handleChangeForm}
                    />
                  </Grid>
                  <Grid item md={6}>
                    <input
                      type="text"
                      placeholder="Prénom"
                      name="lastName"
                      value={lastName}
                      onChange={handleChangeForm}
                    />
                  </Grid>
                  <Grid item md={12}>
                    <input
                      type="email"
                      placeholder="Adresse email"
                      name="email"
                      value={email}
                      onChange={handleChangeForm}
                    />
                  </Grid>
                  <Grid item md={12}>
                    <textarea
                      type="text"
                      placeholder="Message"
                      name="message"
                      value={message}
                      onChange={handleChangeForm}
                    />
                  </Grid>
                </Grid>
                <button onClick={(e) => handleSubmitFormContact(e)}>
                  Envoyer
                </button>
              </div>
            </form>
          </Grid>
        </Grid>
      </div>
    </div>
  );
};

export default ContactsLanding;
