import React from "react";
import LogoDgx from "@shared/assets/icons/dtx.svg";
import { Link } from "react-router-dom";

const NavLanding = () => {
  const credentials = window.localStorage.getItem("credentials");

  return (
    <div className="headerLanding">
      <Link style={{ textDecoration: "none" }} to="/landing">
        <div className="StyleLogo">
          <div className="logoLanding">
            <img src={LogoDgx} alt="DigitExpert" />
          </div>
          <h1>DigitExpert</h1>
        </div>
      </Link>
      <nav>
        <ul>
          <li>
            <Link style={{ textDecoration: "none" }} to="/landing">
              Accueil
            </Link>
          </li>
          <li>
            <a style={{ textDecoration: "none" }} href="/landing#avantages">
              Avantages
            </a>
          </li>
          <li>
            <a
              style={{ textDecoration: "none" }}
              href="/landing#fonctionnalites"
            >
              Fonctionnalités
            </a>
          </li>
          <li>
            <a style={{ textDecoration: "none" }} href="/landing#offre">
              Offres
            </a>
          </li>
          <li>
            <Link style={{ textDecoration: "none" }} to="/faq">
              FAQ
            </Link>
          </li>
          <li>
            {credentials && credentials.length ? (
              <Link
                style={{ textDecoration: "none" }}
                to={`/cabinet/dashboard`}
              >
                Aller sur l'app
              </Link>
            ) : (
              <a
                style={{ textDecoration: "none" }}
                href={`${process.env.REACT_APP_CUSTOM_AKILCAB_URL}/#/auth/login?return_to=${process.env.REACT_APP_CUSTOM_REDIRECTBACK_URL}/landing`}
              >
                Connexion
              </a>
            )}
          </li>
          <li>
            <a
              href={`${process.env.REACT_APP_CUSTOM_AKILCAB_URL}/#/service-requests/choose-request`}
              target="_blank"
            >
              <button>
                {credentials && credentials.length
                  ? "Mes applications"
                  : "S'inscrire"}
              </button>
            </a>
          </li>
        </ul>
      </nav>
    </div>
  );
};

export default NavLanding;
