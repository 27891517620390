export const navigationConfig = [
  {
    id: "applications",
    title: "",
    type: "group",
    icon: "apps",
    children: [
      {
        id: "cabinet-dashboard",
        title: "Dashboard",
        type: "item",
        icon: "apps",
        url: "/cabinet/dashboard",
      },
      {
        id: "cabinet-exercices",
        title: "Exercices",
        type: "item",
        icon: "folder_open",
        url: "/cabinet/exercices/liste",
      },
      {
        id: "cabinet-prospects",
        title: "Prospects",
        type: "item",
        icon: "list_alt",
        url: "/cabinet/prospects/liste",
      },
      {
        id: "cabinet-prospections",
        title: "Prospections",
        type: "item",
        icon: "search",
        url: "/cabinet/prospections/liste",
      },
      {
        id: "cabinet-clients",
        title: "Clients",
        type: "item",
        icon: "supervisor_account",
        url: "/cabinet/dossiers-clients/liste",
      },
      {
        id: "cabinet-missions",
        title: "Missions",
        type: "item",
        icon: "track_changes",
        url: "/cabinet/missions/liste",
      },
      {
        id: "cabinet-invoicing",
        title: "Facturation",
        type: "item",
        icon: "description",
        url: "/cabinet/invoicing/liste",
      },
      // {
      //   id: "cabinet-budgetisation",
      //   title: "Budgétisation",
      //   type: "item",
      //   icon: "monetization_on",
      //   url: "/cabinet/budgetisations/liste",
      // },
      // {
      //   id: "cabinet-planification",
      //   title: "Planification",
      //   type: "item",
      //   icon: "today",
      //   url: "/cabinet/planifications",
      // },
      {
        title: "Teams",
        type: "collapse",
        icon: "group_work",
        children: [
          {
            id: "cabinet-collaborateurs",
            title: "Collaborateurs",
            type: "item",
            icon: "people_alt",
            url: "/cabinet/collaborateurs/liste",
          },
          {
            id: "cabinet-grade",
            title: "Grades",
            type: "item",
            icon: "grade",
            url: "/cabinet/grade/liste",
          },
        ],
      },
      // {
      //   id: "cabinet-factures",
      //   title: "Factures",
      //   type: "item",
      //   icon: "assessment",
      //   url: "/cabinet/factures/liste",
      // },
      // {
      //   id: "cabinet-bibliotheque",
      //   title: "Bibliothèque",
      //   type: "item",
      //   icon: "book",
      //   url: "/cabinet/bibliotheques/liste",
      // },
      // {
      //   id: "cabinet-ged",
      //   title: "Ged",
      //   type: "item",
      //   icon: "wb_cloudy",
      //   url: "/cabinet/ged/liste",
      // },
    ],
  },
];

export const clientNavigationConfig = [
  {
    id: "applications",
    title: "",
    type: "group",
    icon: "apps",
    children: [
      // {
      //   id: "client-dashboard",
      //   title: "Dashboard",
      //   type: "item",
      //   icon: "apps",
      //   url: "/client/dashboard",
      // },
      {
        id: "client-exercices",
        title: "Exercices",
        type: "item",
        icon: "folder_open",
        url: "/client/exercices/liste",
      },
      {
        id: "client-prospects",
        title: "Prospects",
        type: "item",
        icon: "list_alt",
        url: "/client/prospects/liste",
      },
      {
        id: "client-prospections",
        title: "Prospections",
        type: "item",
        icon: "search",
        url: "/client/prospections/liste",
      },
      {
        id: "client-clients",
        title: "Clients",
        type: "item",
        icon: "supervisor_account",
        url: "/client/dossiers-clients/liste",
      },
      {
        id: "client-missions",
        title: "Missions",
        type: "item",
        icon: "track_changes",
        url: "/client/missions/liste",
      },
      {
        id: "cabinet-invoicing",
        title: "Facturation",
        type: "item",
        icon: "description",
        url: "/cabinet/invoicing/liste",
      },
      // {
      //   id: "client-budgetisation",
      //   title: "Budgétisation",
      //   type: "item",
      //   icon: "monetization_on",
      //   url: "/client/budgetisations/liste",
      // },
      // {
      //   id: "client-planification",
      //   title: "Planification",
      //   type: "item",
      //   icon: "today",
      //   url: "/client/planifications",
      // },
      {
        title: "Teams",
        type: "collapse",
        icon: "group_work",
        children: [
          {
            id: "cabinet-collaborateurs",
            title: "Collaborateurs",
            type: "item",
            icon: "people_alt",
            url: "/cabinet/collaborateurs/liste",
          },
          {
            id: "cabinet-grade",
            title: "Grades",
            type: "item",
            icon: "grade",
            url: "/cabinet/grade/liste",
          },
        ],
      },
      // {
      //   id: "client-factures",
      //   title: "Factures",
      //   type: "item",
      //   icon: "assessment",
      //   url: "/client/factures/liste",
      // },
      // {
      //   id: "client-bibliotheque",
      //   title: "Bibliothèque",
      //   type: "item",
      //   icon: "book",
      //   url: "/client/bibliotheques/liste",
      // },
      // {
      //   id: "client-ged",
      //   title: "Ged",
      //   type: "item",
      //   icon: "wb_cloudy",
      //   url: "/client/ged/liste",
      // },
    ],
  },
];
