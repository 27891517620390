import React, { useLayoutEffect } from "react";
import ContentLanding from "./components/ContentLanding";
import ContentPage from "./ContentPage";
import crossStorageService from "app/services/crossStorageService";
import { useHistory } from "react-router";

const Landing = () => {
  const history = useHistory();

  const STORAGE = () => {
    crossStorageService
      .onConnect()
      .then(() => {
        return crossStorageService.get("akilCabCredentials");
      })
      .then((res) => {
        if (!res) {
          return false;
        }
        history.push("/cabinet/dashboard");
      });
  };

  useLayoutEffect(() => {
    STORAGE();
  }, []);

  return (
    <ContentPage>
      <ContentLanding />
    </ContentPage>
  );
};

export default Landing;
