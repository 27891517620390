import promise from "promise";
import ApiAkilcabService from "@core/services/api-akilcab/api-akilcab.service";

export default class LoginAkilcab {
  constructor() {
    this._api = new ApiAkilcabService();
    this.endpoint = {
      akilcab: "akilcab",
      account: "accounts",
      profiles: "profiles",
    };
  }

  /**
   * @returns {any}
   */
  get credentials() {
    return JSON.parse(localStorage.getItem("akilCabCredentials"));
  }

  /**
   *
   * @param {*} data
   * @returns
   */
  postLoginDgx(data, XApi) {
    return this._api
      ._post(`${this.endpoint.account}/login`, data, XApi)
      .then((response) => response.data)
      .then((response) => response.data)
      .catch((error) => promise.reject(error));
  }

  /**
   *
   * @param {*} profileId
   * @returns
   */
  getProfileAkilcab(profileId, headers) {
    return this._api
      ._get(`${this.endpoint.profiles}/${profileId}/token`, "", headers)
      .then((response) => response.data)
      .then((response) => response.data)
      .catch((error) => promise.reject(error));
  }

  /**
   * @param credentials
   */
  storeToken(credentials) {
    localStorage.setItem("akilCabCredentials", JSON.stringify({ credentials }));
  }

  /**
   * @return {any}
   */
  get structures() {
    const response = this.credentials;
    return response && response.credentials && response.credentials.structures;
  }

  /**
   * @returns {any}
   */
  get activeStructure() {
    const response = this.credentials;
    return (
      response && response.credentials && response.credentials.activeStructure
    );
  }

  postEmailLanding(data) {
    return this._api
      ._post(`public/contacts`, data)
      .then((response) => response.data)
      .then((response) => response.data)
      .catch((error) => promise.reject(error));
  }
}
