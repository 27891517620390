import React from "react";
import ContentPage from "../../ContentPage";
import AccordionQuestion from "./AccordionQuestion";

const Faq = () => {
  return (
    <ContentPage>
      <div className="styleFaq">
        <h1>Questions fréquemments posées</h1>
        <AccordionQuestion />
        <h1>Avez-vous d'autres questions ?</h1>
        <p className="questionText">
          Si vous ne trouvez pas de réponse à votre question dans notre FAQ,
          vous pouvez toujours nous contacter. Nous vous répondrons dans un bref
          délai !
        </p>
      </div>
    </ContentPage>
  );
};

export default Faq;
