import promise from "promise";
import ApiService from "@core/services/api/api.service";

export default class ExerciseService {
  constructor() {
    this.endpoint = "exercises";
    this._api = new ApiService();
  }

  /**
   * @param {*} params
   */
  getExercises(params) {
    return this._api
      ._get(`${this.endpoint}`, params)
      .then((response) => response.data)
      .catch((error) => promise.reject(error));
  }

  /**
   *
   * @param {*} exerciseId
   * @param {*} modules
   * @returns
   */
  getExerciseChart(exerciseId, modules) {
    return this._api
      ._get(`${this.endpoint}/exercise/${exerciseId}/module/${modules}`)
      .then((response) => response.data)
      .then((response) => response.data)
      .catch((error) => promise.reject(error));
  }

  /**
   * @param {*} exerciseId
   * @returns
   */
  getExercise(exerciseId) {
    return this._api
      ._get(`${this.endpoint}/${exerciseId}`)
      .then((response) => response.data)
      .then((response) => response.data)
      .catch((error) => promise.reject(error));
  }

  /**
   * @param {*} data
   * @returns
   */
  postExercise(data) {
    return this._api
      ._post(`${this.endpoint}`, data)
      .then((response) => response.data)
      .then((response) => response.data)
      .catch((error) => promise.reject(error));
  }

  /**
   * @param {*} exerciseId
   * @param {*} data
   * @returns
   */
  putExercise(exerciseId, data) {
    return this._api
      ._put(`${this.endpoint}/${exerciseId}`, data)
      .then((response) => response.data)
      .then((response) => response.data)
      .catch((error) => promise.reject(error));
  }

  /**
   * @param {*} exerciseId
   */
  deleteExercice(exerciseId) {
    return this._api
      ._delete(`${this.endpoint}/${exerciseId}`)
      .then((response) => response.data)
      .then((response) => response.data)
      .catch((error) => promise.reject(error));
  }
}
