import React from "react";
import { ThemeProvider } from "@material-ui/styles";
import { useSelector } from "react-redux";

function FuseTheme(props) {
  const mainTheme = useSelector(({ fuse }) => fuse.settings.mainTheme);
  const finalTheme = {
    ...mainTheme,
    overrides: {
      MuiPaginationItem: {
        outlinedPrimary: {
          color: mainTheme.palette.primary.main,
          "&.Mui-selected": {
            color: "white",
            backgroundColor: mainTheme.palette.primary.main,
          },
        },
      },
    },
  };

  return <ThemeProvider theme={finalTheme}>{props.children}</ThemeProvider>;
}

export default React.memo(FuseTheme);
