import React from "react";

export const CabinetEmailLettreMissonConfig = {
  settings: {
    layout: {
      config: {
        navbar: {
          display: false,
        },
        toolbar: {
          display: false,
        },
        footer: {
          display: false,
        },
        leftSidePanel: {
          display: false,
        },
        rightSidePanel: {
          display: false,
        },
      },
    },
  },
  routes: [
    {
      path: "/cabinet/mission-letter/:missionLetterId/token/:token/:actionType/:prospectOrClient",
      component: React.lazy(() =>
        import(
          "./Components/ViewFolderMission/Content/FolderLettreMissionMail/DisplayLettreMissionMail"
        )
      ),
    },
    {
      path: "/cabinet/mission-letter/:missionLetterId/:actionType/:prospectOrClient",
      component: React.lazy(() =>
        import(
          "./Components/ViewFolderMission/Content/FolderLettreMissionMail/DisplayLettreMissionMail"
        )
      ),
    },
  ],
};
