import React from "react";

export const CabinetCollaborateursAppConfig = {
  settings: {
    layout: {
      config: {},
    },
  },
  routes: [
    {
      path: "/cabinet/collaborateurs/liste",
      component: React.lazy(() => import("./CabinetCollaborateursApp")),
    },
    {
      path: "/cabinet/collaborateurs/:userId?/(create|edite)",
      component: React.lazy(() =>
        import("./components/CollaborateurForm/DisplayCollaborateurForm")
      ),
    },
    {
      path: "/cabinet/collaborateur/:userId/detail",
      component: React.lazy(() =>
        import("./components/VoirCollaborateur/DisplayDetailCollaborateur")
      ),
    },
  ],
};
