import React, { useState, useEffect, useCallback } from "react";
import { toast } from "react-toastify";

import {
  Avatar,
  Icon,
  Typography,
  Tooltip,
  Popover,
  MenuItem,
} from "@material-ui/core";

import AkilcabService from "@core/services/akilcab/akilcab.service";
import authService from "app/services/authService/authService";
import crossStorageService from "app/services/crossStorageService";
import { useUserCredentials } from "@shared/components/useContext/useContext";
import ExerciseService from "@core/services/exercise/exercise.service";

const button = {
  height: "100%",
  paddingTop: "10px",
  color: "rgb(238, 113, 34)",
  cursor: "pointer",
  paddingRight: "25px",
  more_vert: {
    height: "100%",
    paddingTop: "10px",
    color: "rgb(238, 113, 34)",
    cursor: "pointer",
    paddingRight: "30px",
  },
};
const navigation = {
  textDecoration: "none",
  color: "#000",
};

function UserMenu(props) {
  const akilcabService = new AkilcabService();
  const { setCredentials } = useUserCredentials();
  const credentials = JSON.parse(localStorage.getItem("credentials"));
  const exerciseService = new ExerciseService();

  const [isAuth, setIsAuth] = useState(false);
  const [userMenu, setUserMenu] = useState(null);
  const [applications, setApplications] = useState([]);
  const [switchStructure, setSwitchStructure] = useState(null);

  const STORAGE = useCallback(() => {
    crossStorageService
      .onConnect()
      .then(() => {
        return crossStorageService.get("akilCabCredentials");
      })
      .then((res) => {
        isRedirection(res);
        if (!res) {
          setIsAuth(false);
          localStorage.removeItem("credentials");
          sessionStorage.removeItem("credentials");
          return false;
        }

        const credentials = JSON.parse(res);
        authService.setCredentials(credentials);
        setCredentials(credentials);
        setIsAuth(true);
      });
  }, [setIsAuth]);

  const getExercises = React.useCallback(() => {
    if (credentials) {
      exerciseService
        .getExercises()
        .then((res) => {
          handleSelectLastYear(res.data);
        })
        .catch(() =>
          toast.warn(
            "Une erreur est survenue lors de l'affichage des exercices"
          )
        );
    }
  }, [credentials]);

  useEffect(() => {
    getApplications();
    STORAGE();
    getExercises();
  }, [STORAGE]);

  const isRedirection = (result) => {
    const pathname = window.location.pathname;
    if (!result && pathname.includes("cabinet")) {
      window.location.href = `${process.env.REACT_APP_CUSTOM_REDIRECTBACK_URL}/landing`;
    }
  };

  const handleOpenApp = (event) => {
    setUserMenu(event.currentTarget);
  };
  const userMenuClose = () => {
    setUserMenu(null);
  };
  const structureClick = (e) => {
    setSwitchStructure(e.currentTarget);
  };
  const structureClose = () => {
    setSwitchStructure(null);
  };
  const logout = () => {
    authService.logout();
  };
  const switchAccount = (e, structure) => {
    window.location.href = `${process.env.REACT_APP_CUSTOM_AKILCAB_URL}/#/switch?account_id=${structure.id}&return_to=${process.env.REACT_APP_CUSTOM_REDIRECTBACK_URL}/landing`;
  };
  const getApplications = () => {
    let path = window.location.pathname;
    const param = {
      all: 1,
    };
    if (path.includes("/cabinet")) {
      akilcabService
        .getApplications(param)
        .then((response) => {
          setApplications(response);
        })
        .catch((error) => {
          toast.warn(`${error}`);
        });
    }
  };

  const handleSelectLastYear = (years) => {
    const length = years?.length;
    if (length > 0) {
      const year = years[length - 1];
      localStorage.setItem("exerciceId", year.label);
    }
  };

  const user = isAuth && credentials?.user;
  const accountantFirm =
    isAuth && credentials?.activeStructure?.accounting_firm?.social_reason;
  const structures = isAuth && credentials ? credentials.structures : [];

  return (
    <>
      <div className="hidden md:flex flex-col ml-3 items-start">
        <Avatar
          className=""
          alt="user photo"
          src={user.avatar_url ? user.avatar_url : null}
        />
      </div>

      <div className="hidden md:flex flex-col ml-5 mr-5 items-start">
        <Typography
          component="span"
          className="normal-case font-100 flex"
          color="textSecondary"
        >
          {user?.first_name || user?.last_name}
        </Typography>
        <Typography component="span" className="normal-case font-400 flex">
          {accountantFirm}
        </Typography>
      </div>

      <Tooltip
        title="Mes applications"
        style={button}
        placement="bottom"
        onClick={handleOpenApp}
      >
        <Icon className="text-25 ml-12 hidden sm:flex" variant="action">
          apps
        </Icon>
      </Tooltip>

      <Popover
        open={Boolean(userMenu)}
        anchorEl={userMenu}
        onClose={userMenuClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
        classes={{
          paper: "py-8",
        }}
      >
        <>
          {applications.map((item) => (
            <a
              style={navigation}
              key={item.id}
              href={`${item.link}`}
              target="_blank"
              rel="noopener noreferrer"
            >
              <MenuItem>
                <img src={item?.logo} className="mr-3" height="30" />
                {item?.wording}
              </MenuItem>
            </a>
          ))}
        </>
      </Popover>

      <Tooltip title="Notifications" style={button} placement="bottom">
        <Icon className="text-25 hidden sm:flex" variant="action">
          notifications_none
        </Icon>
      </Tooltip>

      <Tooltip title="Ticket" style={button} placement="bottom">
        <Icon className="text-25 hidden sm:flex" variant="action">
          forum
        </Icon>
      </Tooltip>
      <Tooltip title="Configuration" style={button} placement="bottom">
        <Icon className="text-30 hidden sm:flex" variant="action">
          settings
        </Icon>
      </Tooltip>

      <Tooltip
        title="Déconnexion"
        onClick={logout}
        style={button}
        placement="bottom"
      >
        <Icon className="text-30 mr-50 ml-12 hidden sm:flex" variant="action">
          power_settings_new
        </Icon>
      </Tooltip>

      <Tooltip
        title="Changer de cabinet"
        style={button.more_vert}
        placement="bottom"
        onClick={structureClick}
      >
        <Icon>more_vert</Icon>
      </Tooltip>

      <Popover
        open={Boolean(switchStructure)}
        anchorEl={switchStructure}
        onClose={structureClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
        classes={{
          paper: "py-8",
        }}
      >
        <React.Fragment>
          {structures &&
            structures.map((structure) => (
              <MenuItem
                key={structure?.id}
                component={null}
                to={null}
                onClick={(e) => switchAccount(e, structure)}
              >
                {structure.accounting_firm.social_reason !== null
                  ? `${structure.accounting_firm.social_reason}`
                  : `${structure.accounting_firm.first_name} ${structure.accounting_firm.last_name}`}
              </MenuItem>
            ))}
        </React.Fragment>
      </Popover>
    </>
  );
}

export default UserMenu;
