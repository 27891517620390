import React from "react";

export const CabinetInvoicingAppConfig = {
  settings: {
    layout: {
      config: {},
    },
  },
  routes: [
    {
      path: "/cabinet/invoicing/liste",
      component: React.lazy(() => import("./CabinetInvoicingApp")),
    },
    {
      path: "/cabinet/invoicing/:typeInvoicing?/:invoicingId?/(create|edite)",
      component: React.lazy(() =>
        import("./components/invoicingFormFolder/ViewInvoicingForm")
      ),
    },
    {
      path: "/cabinet/invoice/:missionIdOrMissionLetterId?/create/:typeInvoicing?",
      component: React.lazy(() =>
        import("./components/invoicingFormFolder/ViewInvoicingForm")
      ),
    },
    {
      path: "/cabinet/invoicing/view/:invoicingId/:typeInvoicing/:typeInvoicingId",
      component: React.lazy(() =>
        import("./components/viewInvoicing/ViewInvoicing")
      ),
    },
    {
      path: "/cabinet/invoice/printout/:invoicingId/:typeInvoicing",
      component: React.lazy(() =>
        import("./components/viewInvoicing/ViewInvoicingForm")
      ),
    },
    {
      path: "/cabinet/invoice/:invoicingId/edite/:typeInvoice",
      component: React.lazy(() =>
        import("./components/invoicingFormFolder/ViewInvoicingForm")
      ),
    },
  ],
};
