import React from "react";
import { Redirect } from "react-router-dom";
import { FuseUtils } from "@fuse";

import { appsConfigs } from "app/main/apps/AppsConfigs";
import { landingConfig } from "app/main/landing/LandingConfig";

const routeConfigs = [...appsConfigs, landingConfig];

const routes = [
  ...FuseUtils.generateRoutesFromConfigs(routeConfigs),
  { path: "", component: () => <Redirect to="/landing" /> },
  {
    path: "cabinet",
    component: () => <Redirect to="/cabinet/dashboard" />,
  },
  { path: "client", component: () => <Redirect to="/client/dashboard" /> },
];

export default routes;
