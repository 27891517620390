import React from "react";
import LoaderButton from "./LoaderButton/LoaderButton";

const Boutton = (props) => {
  return (
    <button
      type={props.ButtonType}
      disabled={props.disabled}
      className={props.styleButton}
      onClick={props.actions ? props.actions : null}
      value={props.indexElement}
      id={props.indexQuestion}
    >
      <div className="w-full h-auto flex justify-center items-center">
        {props.displayLoader && <LoaderButton color={props.colorLoader} />}
        <div>{props.buttonName}</div>
      </div>
    </button>
  );
};

export default Boutton;
