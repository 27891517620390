import React from "react";
import loaderIconOrange from "../../assets/icons/loaderOrange.svg";
import loaderIconWhite from "../../assets/icons/loaderWhite.svg";

export default function LoaderButton({ color }) {
  if (color === "orange") {
    return (
      <div className="cadreLoader">
        <img src={loaderIconOrange} alt="loader-orange" />
      </div>
    );
  } else if (color === "white") {
    return (
      <div className="cadreLoader">
        <img src={loaderIconWhite} alt="loader-white" />
      </div>
    );
  }
}
