import React from "react";

export const CabinetMissionsAppConfig = {
  settings: {
    layout: {
      config: {},
    },
  },
  routes: [
    {
      path: "/cabinet/missions/liste",
      component: React.lazy(() => import("./CabinetMissionsApp")),
    },
    {
      path: "/cabinet/missions/:missionId?/(creation|editer)",
      component: React.lazy(() =>
        import("./Components/MissionFolderForm/DisplayMissionForm")
      ),
    },
    {
      path: "/cabinet/customer/:customerId/missions/:missionId?/(creation|editer)",
      component: React.lazy(() =>
        import("./Components/MissionFolderForm/DisplayMissionForm")
      ),
    },
    {
      path: "/cabinet/missions/:missionId/detail",
      component: React.lazy(() =>
        import("./Components/View-Mission-Folder/ViewMission")
      ),
    },
  ],
};
