import React from "react";

export const CabinetExercicesAppConfig = {
  settings: {
    layout: {
      config: {},
    },
  },
  routes: [
    {
      path: "/cabinet/exercices/liste",
      component: React.lazy(() => import("./CabinetExercicesApp")),
    },
    {
      path: "/cabinet/exercices/:exerciceId?/(creation|edition)",
      component: React.lazy(() =>
        import("./components/ExerciceForm/DisplayExerciceForm")
      ),
    },
  ],
};
