import React from "react";

export const UserCredentialsContext = React.createContext(null);

export const UserCredentialsProvider = (props) => {
  const [credentials, setCredentials] = React.useState(() =>
    JSON.parse(
      localStorage.getItem("credentials") ||
        sessionStorage.getItem("credentials")
    )
  );

  return (
    <UserCredentialsContext.Provider
      value={{ credentials, setCredentials }}
      {...props}
    />
  );
};

export const useUserCredentials = () => {
  const context = React.useContext(UserCredentialsContext);

  if (!context) {
    throw new Error("Veuillez passer un contexte comme paramètre.");
  }

  return context;
};
