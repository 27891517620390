import React, { Component } from "react";
import { connect } from "react-redux";

// import history from "@history";
// import _ from "@lodash";
// import * as userActions from 'app/auth/store/actions';
// import { bindActionCreators } from 'redux';
// import * as Actions from 'app/store/actions';
// import firebaseService from 'app/services/firebaseService';
// import auth0Service from 'app/services/auth0Service';
// import jwtService from 'app/services/jwtService';
import authService from "app/services/authService/authService";
// import userService from 'app/services/userService';
// import jwtInterceptor from 'app/interceptors/jwt/jwtInterceptor';
// import crossStorageService from 'app/services/crossStorageService';

class Auth extends Component {
  constructor(props) {
    super(props);

    authService.init();
    // this.checkUserRole();

    // crossStorageService.init();
    // crossStorageService.onConnect().then(function () {
    //   return crossStorageService.get('akilCabCredentials');
    // }).then(function (res) {
    //   authService.setAuthenticatedUser(JSON.parse(res));
    // });

    /**
     * Comment the line if you do not use JWt
     */
    //this.jwtCheck();

    /**
     * Comment the line if you do not use Auth0
     */
    //this.auth0Check();

    /**
     * Comment the line if you do not use Firebase
     */
    //this.firebaseCheck();
  }

  // checkUserRole() {
  //   if (authService.authenticated()) {
  //     const _credentials = authService.credentials();
  //     const structure = _credentials && _credentials.activeStructure;

  //     if (structure) {
  //       if (structure.is_cabinet) {
  //         this._authorize("cabinet");
  //         return true;
  //       } else {
  //         this._authorize("client");
  //         return true;
  //       }
  //     }
  //   } else {
  //     authService.getRedirectUrl();
  //     return false;
  //   }
  // }

  // _authorize(role) {
  //   if (role === "cabinet") {
  //     return _.startsWith("/cabinet")
  //       ? true
  //       : history.replace("/cabinet/dashboard");
  //   }

  //   if (role === "client") {
  //     return _.startsWith("/client")
  //       ? true
  //       : history.replace("/client/dashboard");
  //   }
  // }

  // jwtCheck = () => {
  //   jwtService.on('onAutoLogin', () => {

  //     this.props.showMessage({ message: 'Logging in with JWT' });

  //     /**
  //      * Sign in and retrieve user data from Api
  //      */
  //     jwtService.signInWithToken()
  //       .then(user => {
  //         this.props.setUserData(user);

  //         this.props.showMessage({ message: 'Logged in with JWT' });
  //       })
  //       .catch(error => {
  //         this.props.showMessage({ message: error });
  //       })
  //   });

  //   jwtService.on('onAutoLogout', (message) => {
  //     if (message) {
  //       this.props.showMessage({ message });
  //     }
  //     this.props.logout();
  //   });

  //   jwtService.init();
  // };

  // auth0Check = () => {

  //   auth0Service.init();

  //   if (auth0Service.isAuthenticated()) {
  //     this.props.showMessage({ message: 'Logging in with Auth0' });

  //     /**
  //      * Retrieve user data from Auth0
  //      */
  //     auth0Service.getUserData().then(tokenData => {

  //       this.props.setUserDataAuth0(tokenData);

  //       this.props.showMessage({ message: 'Logged in with Auth0' });
  //     })
  //   }
  // };

  // firebaseCheck = () => {

  //   firebaseService.init();

  //   firebaseService.onAuthStateChanged(authUser => {
  //     if (authUser) {
  //       this.props.showMessage({ message: 'Logging in with Firebase' });

  //       /**
  //        * Retrieve user data from Firebase
  //        */
  //       firebaseService.getUserData(authUser.uid).then(user => {

  //         this.props.setUserDataFirebase(user, authUser);

  //         this.props.showMessage({ message: 'Logged in with Firebase' });
  //       })
  //     }
  //   });
  // };

  render() {
    const { children } = this.props;

    return <React.Fragment>{children}</React.Fragment>;
  }
}

// function mapDispatchToProps(dispatch) {
//   return bindActionCreators({
//     logout: userActions.logoutUser,
//     setUserData: userActions.setUserData,
//     setUserDataAuth0: userActions.setUserDataAuth0,
//     setUserDataFirebase: userActions.setUserDataFirebase,
//     showMessage: Actions.showMessage,
//     hideMessage: Actions.hideMessage
//   },
//     dispatch);
// }

export default connect(null, null)(Auth);
// export default connect(null, mapDispatchToProps)(Auth);
