import { Grid } from "@material-ui/core";
import React, { useState } from "react";
import loginImg from "@shared/assets/images/lettremission.png";
import LogoDgx from "@shared/assets/icons/dtx.svg";
import { Link } from "react-router-dom";

import IconButton from "@mui/material/IconButton";
import OutlinedInput from "@mui/material/OutlinedInput";
import InputLabel from "@mui/material/InputLabel";
import InputAdornment from "@mui/material/InputAdornment";
import FormControl from "@mui/material/FormControl";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import AlternateEmail from "@mui/icons-material/AlternateEmailTwoTone";
import Checkbox from "@mui/material/Checkbox";
import LoginAkilcab from "@core/services/login/login-akilcab.service";
import { toast } from "react-toastify";
import ChevronRight from "@mui/icons-material/ChevronRightTwoTone";
import { ReactComponent as LoaderIcon } from "@shared/assets/icons/loaderWhite.svg";

const label = { inputProps: { "aria-label": "Checkbox demo" } };

const Login = () => {
  const loginAkilcab = new LoginAkilcab();
  const [values, setValues] = useState({
    password: "",
    login: "",
    remember: false,
  });
  const [stateStructure, setStateStructure] = useState([]);
  const [structures, setStructures] = useState({});
  const [statLoader, setStateLoader] = useState(false);

  const handleChange = (prop) => (event) => {
    if (prop === "remember") {
      setValues({ ...values, [prop]: event.target.checked });
    } else {
      setValues({ ...values, [prop]: event.target.value });
    }
  };

  const handleClickShowPassword = () => {
    setValues({
      ...values,
      showPassword: !values.showPassword,
    });
  };

  const handleSubmitLogin = (e) => {
    e.preventDefault();
    if (values.login !== "" && values.password !== "") {
      setStateLoader(true);
      const data = {
        login: values.login,
        password: values.password,
        remember: values.remember,
      };
      const XApi = {
        headers: { "x-api-key": "195a1c039643e402.82821349app007MA" },
      };

      loginAkilcab
        .postLoginDgx(data, XApi)
        .then((res) => {
          setStructures(res);

          if (res.is_statutory_auditory) {
            window.location.href = `${process.env.REACT_APP_CUSTOM_REDIRECTBACK_URL}/cabinet/dashboard`;
            return;
          }

          if (res.structures.length > 1) {
            setStateStructure(res.structures);
            setStateLoader(false);
          } else {
            appValues(res);
          }
        })
        .catch((mess) => {
          if (
            mess &&
            mess.response &&
            mess.response.data &&
            mess.response.data.code === 422
          ) {
            toast.warn(
              `${mess && mess.response && mess.response.data.message}`
            );
            setStateLoader(false);
          } else {
            toast.warn("Le login ou mot de passe est incorrect.");
            setStateLoader(false);
          }
          setStateLoader(false);
        });
    }
  };

  const appValues = (responses, currentId) => {
    const index = currentId && currentId !== undefined ? currentId : 0;
    loginAkilcab.storeToken(responses);

    if (!loginAkilcab.activeStructure) {
      setCredentials(index, responses.token);
      handleRedirection(currentId, index, responses.token);
    }
  };

  const handleRedirection = (profileId, index, token) => {
    if (profileId) {
      setStateLoader(true);
      const headers = {
        headers: {
          "x-accept-version": `v2`,
          "X-Cabinet-Key": `${profileId}`,
          "X-Customer-Key": 0,
          "X-Customer-Folder-Key": 0,
          "X-Api-Key": `195a1c039643e402.82821349app007MA`,
          Authorization: `Bearer ${token}`,
        },
      };
      loginAkilcab
        .getProfileAkilcab(profileId, headers)
        .then((res) => {
          redirectApp(index, res.token);
        })
        .catch(() => {
          toast.warn("Une erreur est survenue lors du login.");
          setStateLoader(false);
        });
    }
  };

  const redirectApp = (currentIndex, token) => {
    setCredentials(currentIndex, token);
    window.location.href = `${process.env.REACT_APP_CUSTOM_REDIRECTBACK_URL}/cabinet/dashboard`;
    setStateLoader(false);
  };

  const setCredentials = (currentIndex, newToken) => {
    if (stateStructure.length > 1) {
      const credentials = JSON.parse(
        localStorage.getItem("akilCabCredentials")
      );
      credentials.credentials.token = newToken;
      credentials.credentials.activeStructure = stateStructure[currentIndex];
      localStorage.setItem("akilCabCredentials", JSON.stringify(credentials));
      localStorage.setItem(
        "credentials",
        JSON.stringify(
          credentials.credentials ? credentials.credentials : credentials
        )
      );
    } else {
      const credentials = JSON.parse(
        localStorage.getItem("akilCabCredentials")
      );
      credentials.credentials.token = newToken;
      credentials.credentials.activeStructure = stateStructure[0];
      localStorage.setItem("akilCabCredentials", JSON.stringify(credentials));
      localStorage.setItem(
        "credentials",
        JSON.stringify(
          credentials.credentials ? credentials.credentials : credentials
        )
      );
    }
  };

  return (
    <div className="content-login relative">
      <Grid container>
        <Grid item md={6}>
          <div className="cadreImgLogin">
            <img src={loginImg} alt="login" />
            <div className="overTextLogin">
              <Link style={{ textDecoration: "none" }} to="/landing">
                <div className="StyleLogo">
                  <div className="logoLanding">
                    <img src={LogoDgx} alt="DigitExpert" />
                  </div>
                  <h1>DigitExpert</h1>
                </div>
              </Link>
              <h2>
                <span>DigitExpert</span>, la solution qui aide le professionnel
                du chiffre à bien planifier l'ensemble de vos missions.
              </h2>
              <p>Optimisez la gestion de vos missions</p>
              <h3>
                Par <span>AKILCAB</span>
              </h3>
            </div>
          </div>
        </Grid>
        <Grid item md={6}>
          <div className="cadreFormlogin">
            {stateStructure && stateStructure.length > 0 ? (
              <>
                <h1>
                  Salut <span> {values && values.login} </span>
                </h1>
                <p>Veuillez sélectionner un compte s'il vous plaît.</p>
              </>
            ) : (
              <>
                <h1>
                  Connectez vous à <span>digitexpert</span>
                </h1>
                <p>
                  Renseignez vos identifiants et accédez à votre espace de
                  travail.
                </p>
              </>
            )}

            <div className="formLogin">
              {stateStructure && stateStructure.length > 0 ? (
                <div className="styleListCabinet">
                  {stateStructure.map((cabinet, index) => (
                    <div
                      onClick={() => appValues(structures, cabinet.profile_id)}
                      key={index}
                    >
                      <p>{cabinet.structure_tag}</p>
                      <p>
                        {cabinet?.accounting_firm?.first_name ||
                          cabinet?.accounting_firm?.social_reason}
                      </p>
                      <p>
                        <ChevronRight />
                      </p>
                    </div>
                  ))}
                </div>
              ) : (
                <form noValidate>
                  <FormControl
                    className="form-control"
                    sx={{ m: 2 }}
                    variant="outlined"
                  >
                    <InputLabel htmlFor="outlined-adornment-weight">
                      Username ou email
                    </InputLabel>
                    <OutlinedInput
                      id="outlined-adornment-weight"
                      value={values.login}
                      onChange={handleChange("login")}
                      className="form-input"
                      endAdornment={
                        <InputAdornment position="end">
                          <AlternateEmail />
                        </InputAdornment>
                      }
                      label="Username ou email"
                    />
                  </FormControl>
                  <FormControl
                    className="form-control"
                    sx={{ m: 1 }}
                    variant="outlined"
                  >
                    <InputLabel htmlFor="outlined-adornment-password">
                      Mot de passe
                    </InputLabel>
                    <OutlinedInput
                      id="outlined-adornment-password"
                      type={values.showPassword ? "text" : "password"}
                      value={values.password}
                      onChange={handleChange("password")}
                      className="form-input"
                      endAdornment={
                        <InputAdornment position="end">
                          <IconButton
                            aria-label="toggle password visibility"
                            onClick={handleClickShowPassword}
                            edge="end"
                          >
                            {values.showPassword ? (
                              <VisibilityOff />
                            ) : (
                              <Visibility />
                            )}
                          </IconButton>
                        </InputAdornment>
                      }
                      label="Mot de passe"
                    />
                  </FormControl>
                  <div className="forgetStyle">
                    <div className="flex items-center">
                      <Checkbox
                        {...label}
                        sx={{
                          color: "#ED7122",
                          "&.Mui-checked": {
                            color: "#ED7122",
                          },
                          "& .MuiSvgIcon-root": { fontSize: 18 },
                        }}
                        onChange={handleChange("remember")}
                      />
                      <p> Se souvenir de moi !</p>
                    </div>

                    <p>
                      <span>Mot de passe oublié</span>
                    </p>
                  </div>
                  <button
                    onClick={(e) => handleSubmitLogin(e)}
                    className="buttonFormLogin"
                  >
                    Connexion
                  </button>
                </form>
              )}
            </div>
            <div className="flex justify-between content-formBar">
              <div className="formBar"></div>
              <span>Ou</span>
              <div className="formBar"></div>
            </div>
            <p>
              <span>
                <a
                  href={`${process.env.REACT_APP_CUSTOM_AKILCAB_URL}/#/service-requests/choose-request`}
                  style={{ textDecoration: "none" }}
                  target="_blank"
                >
                  Inscrivez-vous
                </a>
              </span>{" "}
              si vous n'avez pas encore de compte
            </p>
          </div>
        </Grid>
      </Grid>
      {statLoader ? (
        <div className="loadeloging">
          <LoaderIcon />
        </div>
      ) : (
        ""
      )}
    </div>
  );
};

export default Login;
