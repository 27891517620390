import { CabinetDashboardAppConfig } from "./cabinet-dashboard/CabinetDashboardAppConfig";
import { CabinetProspectsAppConfig } from "./cabinet-prospects/CabinetProspectsAppConfig";
import { CabinetEmailAppConfig } from "./cabinet-prospects/CabinetEmailAppConfig";
import { CabinetProspectionsAppConfig } from "./cabinet-prospections/CabinetProspectionsAppConfig";
import { CabinetListeDossiersAppConfig } from "./cabinet-clients/cabinet-liste-dossiers/CabinetListeDossiersAppConfig";
import { CabinetImportDossiersAppConfig } from "./cabinet-clients/cabinet-import-dossiers/CabinetImportDossiersAppConfig";
import { CabinetMissionsAppConfig } from "./cabinet-missions/CabinetMissionsAppConfig";
import { CabinetExercicesAppConfig } from "./cabinet-exercices/CabinetExercicesAppConfig";
import { CabinetPlanificationsAppConfig } from "./cabinet-planifications/CabinetPlanificationsAppConfig";
import { CabinetCollaborateursAppConfig } from "./cabinet-collaborateurs/CabinetCollaborateursAppConfig";
import { CabinetFacturesAppConfig } from "./cabinet-factures/CabinetFacturesAppConfig";
import { CabinetGradeAppConfig } from "./cabinet-grade/CabinetGradeAppConfig";
import { CabinetEmailLettreMissonConfig } from "./cabinet-missions/CabinetEmailLettreMission";
import { CabinetInvoicingAppConfig } from "./cabinet-invoicing/CabinetInvoicingAppConfig";

export const CabinetAppsConfigs = [
  CabinetDashboardAppConfig,
  CabinetProspectsAppConfig,
  CabinetEmailAppConfig,
  CabinetProspectionsAppConfig,
  CabinetListeDossiersAppConfig,
  CabinetImportDossiersAppConfig,
  CabinetMissionsAppConfig,
  CabinetInvoicingAppConfig,
  CabinetEmailLettreMissonConfig,
  CabinetExercicesAppConfig,
  CabinetPlanificationsAppConfig,
  CabinetGradeAppConfig,
  CabinetCollaborateursAppConfig,
  CabinetFacturesAppConfig,
];
