import { Grid } from "@material-ui/core";
import React from "react";
import LogoDgx from "@shared/assets/icons/dtx.svg";

const Footer = () => {
  return (
    <footer className="content-footer">
      <Grid container spacing={2}>
        <Grid item md={4}>
          <div className="footer-left">
            <div className="StyleLogo">
              <div className="logoLanding">
                <img src={LogoDgx} alt="DigitExpert" />
              </div>
              <h1>DigitExpert</h1>
            </div>
            <p>
              Automatisez la gestion interne de votre cabinet, et accroissez
              votre portefeuille clients.
            </p>
          </div>
        </Grid>
        <Grid item md={8}>
          <div className="footer-right">
            <Grid container>
              <Grid item md>
                <h3>Accueil</h3>
                <p>Avantages</p>
                <p>Fonctionnalités</p>
                <p>Offres</p>
                <p>FAQ</p>
              </Grid>
              <Grid item md>
                <h3>Les avantages</h3>
                <p>La solution normative</p>
                <p>Une prospection fluide et efficace</p>
                <p>
                  Les bons besoins définis, la bonne offre de mission proposée
                </p>
                <p>Des lettres de missions bien évaluées pour votre cabinet</p>
                <p>
                  Le bon collaborateur assigné à la bonne tâche, au bon coût et
                  au bon moment
                </p>
                <p>Suivi de mission efficace, délai respecté</p>
              </Grid>
              <Grid item md>
                <h3>Les fonctionnalités</h3>
                <p>Tableau de bord</p>
                <p>Base de données</p>
                <p>Prospection</p>
                <p>Gestion de missions</p>
                <p>Team management</p>
              </Grid>
              <Grid item md>
                <h3>Les offres</h3>
                <p>Offre exclusive</p>
              </Grid>
              <Grid item md>
                <h3>Contacts</h3>
                <p>+225 27 224 274 06</p>
                <p>+225 27 225 441 17</p>
              </Grid>
            </Grid>
          </div>
        </Grid>
      </Grid>
    </footer>
  );
};

export default Footer;
