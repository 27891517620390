import authService from "app/services/authService/authService";

let credentials = null;
let token = null;
let cabinetId = null;
let customerId = 0;
let customerFolderId = null;

class jwtInterceptor {
  checkAuthenticateOrCredentials() {
    if (authService.authenticated()) {
      credentials = authService.credentials();
    } else {
      const res = authService.getCredentials();
      credentials = res && res.credentials;
    }
  }

  setHeadersVars(credentials) {
    if (credentials) {
      token = credentials.token || null;

      if (credentials.activeStructure) {
        if (credentials.activeStructure.is_cabinet) {
          cabinetId =
            credentials.activeStructure.id || credentials.structures[0].id || 0;
          customerFolderId =
            sessionStorage.getItem("x-customer-folder-key") ||
            localStorage.getItem("x-customer-folder-key") ||
            0;
        } else {
          customerId =
            credentials.activeStructure.id || credentials.structures[0].id || 0;
          cabinetId =
            credentials.activeStructure.accounting_firm ||
            credentials.activeStructure.accounting_firm.id ||
            credentials.structures[0].accounting_firm.id ||
            0;
          customerFolderId = customerId;
        }
      }
    }
  }

  successRequest(request) {
    this.setHeadersVars(credentials);

    if (token) {
      request.headers["X-Api-Key"] = `${process.env.REACT_APP_CUSTOM_KEY_APP}`;
      request.headers["X-App-Key"] = `${process.env.REACT_APP_CUSTOM_CODE_APP}`;
      request.headers["X-Cabinet-Key"] = `${cabinetId}`;
      request.headers["X-Customer-Key"] = `${customerId}`;
      request.headers["X-Customer-Folder-Key"] = `${customerFolderId}`;
      request.headers.Authorization = `Bearer ${token}`;
    }

    return request;
  }
}

const instance = new jwtInterceptor();

export default instance;
