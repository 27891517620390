import React from "react";
import { AppBar, Hidden, Icon } from "@material-ui/core";
import { FuseScrollbars } from "@fuse";
import { makeStyles } from "@material-ui/styles";
import clsx from "clsx";

import Logo from "app/fuse-layouts/shared-components/Logo";
import NavbarFoldedToggleButton from "app/fuse-layouts/shared-components/NavbarFoldedToggleButton";
import NavbarMobileToggleButton from "app/fuse-layouts/shared-components/NavbarMobileToggleButton";
import Navigation from "app/fuse-layouts/shared-components/Navigation";
import ExerciceSelect from "@shared/components/ExerciceFolder/ExerciceSelect";

const useStyles = makeStyles({
  content: {
    overflowX: "hidden",
    overflowY: "auto",
    "-webkit-overflow-scrolling": "touch",
    background: "#fff",
    backgroundRepeat: "no-repeat",
    backgroundSize: "100% 40px, 100% 10px",
    backgroundAttachment: "local, scroll",
    height: "100vh",
  },
});

function NavbarLayout1(props) {
  const classes = useStyles();
  const header = {
    background: "#fff",
    boxShadow: "0 0 7px 0 rgba(0,0,0,0.7)",
  };

  return (
    <div
      className={clsx("flex flex-col overflow-hidden h-full", props.className)}
    >
      <AppBar
        style={header}
        color="primary"
        position="static"
        elevation={0}
        className="flex flex-row items-center flex-shrink h-64 min-h-64 pl-20 pr-0"
      >
        <div className="flex flex-1 pr-8">
          <Logo />
        </div>

        <Hidden mdDown>
          <NavbarFoldedToggleButton className="w-40 h-40 p-0" />
        </Hidden>

        <Hidden lgUp>
          <NavbarMobileToggleButton className="w-40 h-40 p-0">
            <Icon>arrow_back</Icon>
          </NavbarMobileToggleButton>
        </Hidden>
      </AppBar>

      <FuseScrollbars className={clsx(classes.content)}>
        <div className="w-full h-auto mt-10">
          <ExerciceSelect />
        </div>
        <Navigation layout="vertical" />
      </FuseScrollbars>
    </div>
  );
}

export default NavbarLayout1;
