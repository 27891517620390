import axios from "axios";

import jwtInterceptor from "@core/interceptors/jwt/jwtInterceptor";
import errorInterceptor from "@core/interceptors/error/errorInterceptor";
import authService from "app/services/authService/authService";

export default class ApiService {
  constructor() {
    this._api = axios.create();
    this.url = process.env.REACT_APP_CUSTOM_SERVER_API;

    authService.init();
    jwtInterceptor.checkAuthenticateOrCredentials();

    this._api.interceptors.request.use((request) =>
      jwtInterceptor.successRequest(request)
    );

    this._api.interceptors.response.use(
      (response) => response,
      (error) => errorInterceptor.intercept(error)
    );
  }

  /**
   * GET endpoint
   *
   * @param {*} endpoint
   * @param {*} params
   * @param {*} reqOpts
   * @param {*} urls
   */
  _get(endpoint, params, reqOpts, urls) {
    this.url = urls ? urls : this.url;

    if (!reqOpts) {
      reqOpts = {
        params: params,
      };
    }

    this.url = urls ? urls : this.url;
    return this._api.get(this.url + "/" + endpoint, reqOpts);
  }

  /**
   * POST endpoint
   *
   * @param {*} endpoint
   * @param {*} body
   * @param {*} reqOpts
   * @param {*} urls
   */
  _post(endpoint, body, reqOpts, urls) {
    this.url = urls ? urls : this.url;
    return this._api.post(this.url + "/" + endpoint, body, reqOpts);
  }

  /**
   * PUT endpoint
   *
   * @param {*} endpoint
   * @param {*} body
   * @param {*} reqOpts
   * @param {*} urls
   */
  _put(endpoint, body, reqOpts, urls) {
    this.url = urls ? urls : this.url;
    return this._api.put(this.url + "/" + endpoint, body, reqOpts);
  }

  /**
   * PATCH endpoint
   *
   * @param {*} endpoint
   * @param {*} body
   * @param {*} reqOpts
   * @param {*} urls
   */
  _patch(endpoint, body, reqOpts, urls) {
    this.url = urls ? urls : this.url;
    return this._api.patch(this.url + "/" + endpoint, body, reqOpts);
  }

  /**
   * DELETE endpoint
   *
   * @param {*} endpoint
   * @param {*} reqOpts
   * @param {*} urls
   */
  _delete(endpoint, params, reqOpts, urls) {
    this.url = urls ? urls : this.url;

    if (!reqOpts) {
      reqOpts = {
        params: params,
      };
    }

    return this._api.delete(this.url + "/" + endpoint, reqOpts);
  }
}
