import React from "react";

export const CabinetGradeAppConfig = {
  settings: {
    layout: {
      config: {},
    },
  },
  routes: [
    {
      path: "/cabinet/grade/liste",
      component: React.lazy(() => import("./CabinetGradeApp")),
    },
    {
      path: "/cabinet/grade/:gradeId?/(create|edite)",
      component: React.lazy(() =>
        import("./components/GradeForm/DisplayGrade")
      ),
    },
  ],
};
