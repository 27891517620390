const digitExpert = {
  50: "#fdeee4",
  100: "#fad4bd",
  200: "#f6b891",
  300: "#f29c64",
  400: "#f08643",
  500: "#ED7122",
  600: "#e5631e",
  700: "#da5018",
  800: "#d03e12",
  900: "#bf1f09",
  A100: "#fcb283",
  A200: "#f9741f",
  A400: "#f96506",
  A700: "#d15505",
  // contrast: (
  //   50: $dark-primary-text,
  //   100: $dark-primary-text,
  //   200: $dark-primary-text,
  //   300: $dark-primary-text,
  //   400: $dark-primary-text,
  //   500: $light-primary-text,
  //   600: $light-primary-text,
  //   700: $light-primary-text,
  //   800: $light-primary-text,
  //   900: $light-primary-text,
  //   A100: $dark-primary-text,
  //   A200: $dark-primary-text,
  //   A400: $light-primary-text,
  //   A700: $light-primary-text
  // )
};

export default digitExpert;
