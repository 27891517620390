import { digitExpert, digitExpertLight } from "@fuse/fuse-colors";

const themesConfig = {
  default: {
    palette: {
      type: "light",
      primary: digitExpert,
      secondary: {
        light: digitExpertLight[400],
        main: digitExpert[600],
        dark: digitExpertLight[700],
      },
      // error: red
    },
    status: {
      danger: "orange",
    },
  },
};

export default themesConfig;
