import React from "react";

export const CabinetFacturesAppConfig = {
  settings: {
    layout: {
      config: {},
    },
  },
  routes: [
    {
      path: "/cabinet/factures/liste",
      component: React.lazy(() => import("./CabinetFacturesApp")),
    },
    {
      path: "/cabinet/factures/:factureId?/(create|editer)",
      component: React.lazy(() =>
        import("./components/formFacture/DisplayFormFacture")
      ),
    },
  ],
};
