import { toast } from "react-toastify";
import authService from "app/services/authService/authService";

class errorInterceptor {
  constructor() {
    this._auth = authService;
    this._toastr = toast;
  }

  intercept(response) {
    const erroResponse = response && response.response;

    if (
      (erroResponse && erroResponse.status === 422) ||
      (erroResponse &&
        erroResponse.data &&
        erroResponse.data.status_code === 422)
    ) {
      const errors = erroResponse.data;

      if (
        (erroResponse && erroResponse.status === "INVALID_FIELDS") ||
        (erroResponse && erroResponse.data.status === "INVALID_FIELDS")
      ) {
        if (erroResponse.data.length > 0) {
          Object.keys(errors).map((key) => {
            this._toastr.warn(`${errors[key].message}`);
          });
        } else {
          this._toastr.warn(`${errors.message}`);
        }
      } else {
        this._toastr.warn(`${errors.message}`);
      }
    } else if (erroResponse && erroResponse.status === 404) {
      this._toastr.warn(
        `${erroResponse.statusText || erroResponse.data.message}`
      );
    } else if (
      (erroResponse && erroResponse.status === 403) ||
      (erroResponse &&
        erroResponse.data &&
        erroResponse.data.status_code === 403)
    ) {
      if (
        (erroResponse && erroResponse.status === "INVALID_TOKEN") ||
        (erroResponse && erroResponse.data.status_code === "INVALID_TOKEN")
      ) {
        this._toastr.warn(
          `${
            (erroResponse && erroResponse.data.message) ||
            (erroResponse && erroResponse.statusText)
          }`
        );
      } else if (
        (erroResponse && erroResponse.status === "TOKEN_EXPIRED") ||
        (erroResponse && erroResponse.data.status_code === "TOKEN_EXPIRED")
      ) {
        this._toastr.warn(
          `${
            (erroResponse && erroResponse.data.message) ||
            (erroResponse && erroResponse.statusText)
          }`
        );
      }

      this._auth.logout();
    } else if (
      ((erroResponse && erroResponse.status === 402) ||
        (erroResponse &&
          erroResponse.data &&
          erroResponse.data.status_code === 402)) &&
      ((erroResponse && erroResponse.status === "PAYMENT_REQUIRED") ||
        (erroResponse && erroResponse.data.status_code === "PAYMENT_REQUIRED"))
    ) {
      this._toastr.warn(`${erroResponse.data.message}`);
    } else if (
      (erroResponse && erroResponse.status === 401) ||
      (erroResponse &&
        erroResponse.data &&
        erroResponse.data.status_code === 401)
    ) {
      if (
        (erroResponse && erroResponse.status === "UNAUTHORIZED_USER") ||
        (erroResponse &&
          erroResponse.data &&
          erroResponse.data.status_code === "UNAUTHORIZED_USER")
      ) {
        this._toastr.warn(`${erroResponse && erroResponse.data.message}`);
      }

      if (
        erroResponse &&
        erroResponse.data.message === `Token invalid/expiré`
      ) {
        this._toastr.warn(`Votre session a exipré, vous serez déconnecté.`);
      }

      this._auth.logout();
    } else if (
      (erroResponse && erroResponse.status === 400) ||
      (erroResponse &&
        erroResponse.data &&
        erroResponse.data.status_code === 400)
    ) {
      this._toastr.warn(`${erroResponse && erroResponse.data.message}`);
    } else if (erroResponse && erroResponse.status === 0) {
      this._toastr.warn(`Une connexion lente ou inexistante a été détectée.`);
    }

    if (erroResponse && erroResponse.config) {
      erroResponse.config.__isRetryRequest = true;
    }
    return Promise.reject({ ...erroResponse });
  }
}

const instance = new errorInterceptor();

export default instance;
