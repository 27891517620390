import React from "react";

export const CabinetProspectsAppConfig = {
  settings: {
    layout: {
      config: {},
    },
  },
  routes: [
    {
      path: "/cabinet/prospects/liste",
      component: React.lazy(() => import("./CabinetProspectsApp")),
    },
    {
      path: "/cabinet/prospects/:prospectId?/(creation|editer)",
      component: React.lazy(() =>
        import("./components/Prospect-form/IndexFormProspect")
      ),
    },
    {
      path: "/cabinet/prospects/:prospectId/details/:step?",
      component: React.lazy(() =>
        import("./components/actionsProspect/voirProspect/DetailsProspect")
      ),
    },
    {
      path: "/cabinet/prospects/:prospectId/prospection/:prospecting/survey/:surveyId/questionnaire/(creation|edition)",
      component: React.lazy(() =>
        import("./components/Questionnaire/Questionnaire")
      ),
    },
    {
      path: "/cabinet/dossiers-clients/:customerId/prospection/:prospecting/survey/:surveyId/questionnaire/(creation|edition)",
      component: React.lazy(() =>
        import("./components/Questionnaire/Questionnaire")
      ),
    },
    {
      path: "/cabinet/model/:typeModel/prospects/:prospectId/prospecting/:prospecting/survey/:surveyId/exercices/:exercicesId/(creation|edition)",
      component: React.lazy(() =>
        import("./components/QuestionnaireStandar/QuestionnaireStandar")
      ),
    },
    {
      path: "/cabinet/model/:typeModel/customer/:clientId/prospecting/:prospecting/survey/:surveyId/exercices/:exercicesId/(creation|edition)",
      component: React.lazy(() =>
        import("./components/QuestionnaireStandar/QuestionnaireStandar")
      ),
    },
    {
      path: "/cabinet/model-customize/:customizeSurveyId/prospects/:prospectId/prospecting/:prospecting/survey/:surveyId/exercices/:exerciceId/(creation|edition)",
      component: React.lazy(() =>
        import("./components/QuestionnaireCustomize/QuestionnaireCustomize")
      ),
    },
    {
      path: "/cabinet/model-customize/:customizeSurveyId/customers/:customerId/prospecting/:prospecting/survey/:surveyId/exercices/:exerciceId/(creation|edition)",
      component: React.lazy(() =>
        import("./components/QuestionnaireCustomize/QuestionnaireCustomize")
      ),
    },
    {
      path: "/cabinet/prospects/:prospectId/exercices/:exerciceId/prospection-prospect",
      component: React.lazy(() =>
        import("./components/actionsProspect/voirProspect/ListModelProspection")
      ),
    },
    {
      path: "/cabinet/prospects/:prospectId/exercices/:exerciceId/prospect-client",
      component: React.lazy(() =>
        import("./components/actionsProspect/voirProspect/ListModelProspection")
      ),
    },
    {
      path: "/cabinet/dossiers-clients/:clientId/exercices/:exerciceId/prospect-client",
      component: React.lazy(() =>
        import("./components/actionsProspect/voirProspect/ListModelProspection")
      ),
    },
    {
      path: "/cabinet/prospects/import",
      component: React.lazy(() =>
        import("./components/Prospect-import/IndexImportProspect")
      ),
    },
    {
      path: "/cabinet/prospects/liste-imports",
      component: React.lazy(() =>
        import("./components/Prospect-import/ListeImports")
      ),
    },
    {
      path: "/cabinet/prospects/imports/list-prospect/:importId",
      component: React.lazy(() =>
        import("./components/Prospect-import/DisplayImportProspect")
      ),
    },
    {
      path: "/cabinet/prospects/:prospectId/prospections/:prospectionId/fiche-besoin/:needSheetId?",
      component: React.lazy(() =>
        import("./components/DetailsFicheBesoinForm")
      ),
    },
    {
      path: "/cabinet/dossiers-clients/:clientId/prospections/:prospectionId/fiche-besoin/:needSheetId?",
      component: React.lazy(() =>
        import("./components/DetailsFicheBesoinForm")
      ),
    },
    {
      path: "/cabinet/prospects/:prospectId/prospections/fiche-besoin",
      component: React.lazy(() =>
        import("./components/ListProspectingByFicheBesoin")
      ),
    },
    {
      path: "/cabinet/dossiers-clients/:clientId/fiche-besoin/:needSheetId/details",
      component: React.lazy(() =>
        import("./components/ListBesoinByFicheBesoin/ListBesoin")
      ),
    },
    {
      path: "/cabinet/prospects/:prospectId/fiche-besoin/:needSheetId/details",
      component: React.lazy(() =>
        import("./components/ListBesoinByFicheBesoin/ListBesoin")
      ),
    },
    {
      path: "/cabinet/prospects/:prospectId/fiche-besoin/:needSheetId/besoin/:needId",
      component: React.lazy(() =>
        import("./components/DetailsFicheBesoinForm")
      ),
    },
    {
      path: "/cabinet/prospects/:prospectId/fiche-besoin/projet-mission",
      component: React.lazy(() =>
        import("./components/DetailsProjetMissionListFicheBesoin")
      ),
    },
    {
      path: "/cabinet/prospects/:prospectId/projet-mission/:missionProjetId?/(creation|edite)",
      component: React.lazy(() => import("./components/DetailsProjetMission")),
    },
    {
      path: "/cabinet/dossiers-clients/:clientId/projet-mission/:missionProjetId?/:typeAction",
      component: React.lazy(() => import("./components/DetailsProjetMission")),
    },
    {
      path: "/cabinet/prospects/:prospectId/fiche-besoin/:ficheBesoinId?/creation/:missionProjetId?",
      component: React.lazy(() => import("./components/DetailsProjetMission")),
    },
    {
      path: "/cabinet/prospects/:prospectId/projet-missions/offre-mission",
      component: React.lazy(() =>
        import("./components/ListProjetMissionByOffreMission")
      ),
    },
    {
      path: "/cabinet/prospects/:prospectId/projet-missions/:projetMissionId?/offre-mission/:offreMissionId?",
      component: React.lazy(() =>
        import("./components/DisplayDetailOffreMission")
      ),
    },
    {
      path: "/cabinet/dossiers-clients/:clientId/projet-missions/:projetMissionId?/offre-mission/:offreMissionId?",
      component: React.lazy(() =>
        import("./components/DisplayDetailOffreMission")
      ),
    },
    {
      path: "/cabinet/prospects/:prospectId/offre-mission/:offreMissionId/edite",
      component: React.lazy(() =>
        import("./components/DisplayDetailOffreMission")
      ),
    },
    {
      path: "/cabinet/dossiers-clients/:clientId/offre-mission/:offreMissionId/edite",
      component: React.lazy(() =>
        import("./components/DisplayDetailOffreMission")
      ),
    },
    {
      path: "/cabinet/prospects/:prospectId/fiche-accept-client/:facId?/(create|edite)",
      component: React.lazy(() =>
        import("./components/FAC/ViewFicheAcceptClient")
      ),
    },
    {
      path: "/cabinet/prospects/:prospectId/offer-mission/:offreMissionId/fiche-accept-mission/:acceptMissionId?/(create|edite)",
      component: React.lazy(() =>
        import("./components/Folder-Accept-Mission/DisplayAcceptMission")
      ),
    },
    {
      path: "/cabinet/client/:clientId/missions/:missionId/maintien-mission/:maintienId/(create|edite)",
      component: React.lazy(() =>
        import("./components/Folder-Accept-Mission/DisplayAcceptMission")
      ),
    },
    {
      path: "/cabinet/dossier-client/:clientId/offer-mission/:offreMissionId/fiche-accept-mission/:acceptMissionId?/(create|edite)",
      component: React.lazy(() =>
        import("./components/Folder-Accept-Mission/DisplayAcceptMission")
      ),
    },
    {
      path: "/cabinet/prospects/:prospectId/accept-mission/:acceptMissionId/lettre-mission/:missionLetterId?/(create|edite)",
      component: React.lazy(() =>
        import("./components/view-lettre-mission/DisplayMissionLetter")
      ),
    },
    {
      path: "/cabinet/dossier-client/:clientId/accept-mission/:acceptMissionId/lettre-mission/:missionLetterId?/(create|edite)",
      component: React.lazy(() =>
        import("./components/view-lettre-mission/DisplayMissionLetter")
      ),
    },
  ],
};
