import React from "react";

export const CabinetListeDossiersAppConfig = {
  settings: {
    layout: {
      config: {},
    },
  },
  routes: [
    {
      path: "/cabinet/dossiers-clients/liste",
      component: React.lazy(() => import("./CabinetListeDossiersApp")),
    },
    {
      path: "/cabinet/dossiers-clients/:clientId?/(creation|edition)",
      component: React.lazy(() => import("./CustomerForm/CreateCustomerForm")),
    },
    {
      path: "/cabinet/dossiers-clients/:clientId/details",
      component: React.lazy(() =>
        import("./CustomerForm/VoirCustomerFolder/DetailCustomerFolder")
      ),
    },
    {
      path: "/cabinet/dossiers-clients/:clientId/list-prospecting",
      component: React.lazy(() =>
        import("./CustomerForm/VoirCustomerFolder/ListProspectingByCustomer")
      ),
    },
    {
      path: "/cabinet/dossiers-clients/:clientId/list-projet-mission",
      component: React.lazy(() =>
        import("./CustomerForm/VoirCustomerFolder/ListMissionProjetCustomer")
      ),
    },
    {
      path: "/cabinet/dossiers-clients/:clientId/list-fiche-besoin",
      component: React.lazy(() =>
        import("./CustomerForm/VoirCustomerFolder/ListFicheBesoinCustomer")
      ),
    },
    {
      path: "/cabinet/client/:clientId/maintient-client/:maintienClientId/(create|edite)",
      component: React.lazy(() =>
        import("./FolderMaintienClient/DisplayMaintienClient")
      ),
    },
  ],
};
