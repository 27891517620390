import React from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Buttons from "@shared/components/Button";
import Modal from "@mui/material/Modal";
import { NotificationImportantTwoTone } from "@mui/icons-material";
import { useHistory } from "react-router";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
};

export default function ModaleErrorExercise({
  formErrorMessage,
  handleClose,
  openModal,
  action,
}) {
  const history = useHistory();
  const handleClickClose = () => {
    if (action && action === "NEW_EXERCISE") {
      handleClose(true);
    } else {
      handleClose(false);
    }
  };

  const handleClickSubmit = () => {
    if (action && action === "NEW_EXERCISE") {
      history.push(`/cabinet/exercices/creation`);
      handleClose(false);
    } else {
      handleClose(false);
    }
  };

  return (
    <Modal
      open={openModal}
      onClose={() => handleClickClose()}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style}>
        <Typography id="modal-modal-title" variant="h4" component="h1">
          <div className="hearderModalReponse text-center w-full">
            <NotificationImportantTwoTone sx={{ fontSize: 50 }} />
            <p className="font-light">Avertissement !</p>
          </div>
        </Typography>
        <Typography id="modal-modal-description" sx={{ mt: 2 }}>
          <div className="w-full h-auto">
            <p
              className={`reponseDataStyle my-2 ${action ? "text-center" : ""}`}
            >
              {formErrorMessage}
            </p>
          </div>
          <div
            className={`w-full h-auto flex justify-${
              action ? "center" : "start"
            } mt-10`}
          >
            <Buttons
              styleButton={`${
                action ? "success__buttonModale" : "white__buttonModale"
              }`}
              buttonName={`${action ? "Menu Exercice" : "Fermer"}`}
              actions={() => handleClickSubmit()}
            />
          </div>
        </Typography>
      </Box>
    </Modal>
  );
}
