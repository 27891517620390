import { CrossStorageClient } from "cross-storage";

class crossStorageService {
  storage = CrossStorageClient;

  constructor() {
    this.storage = new CrossStorageClient(
      process.env.REACT_APP_CUSTOM_AKILCAB_URL,
      {
        timeout: 30000,
        frameId: "storageFrame",
      }
    );
  }

  onConnect() {
    return this.storage.onConnect();
  }

  get(key) {
    return this.storage.get(key);
  }

  set(key, value, ttl) {
    return this.storage.set(key, value, ttl);
  }

  close() {
    return this.storage.clear();
  }
}

const instance = new crossStorageService();

export default instance;
