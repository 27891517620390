import { Grid } from "@material-ui/core";
import React from "react";
import solutionImg from "@shared/assets/images/solution.png";
import prospectImg from "@shared/assets/images/prospect.png";
import besoinsImg from "@shared/assets/images/besoins.jpeg";
import lettremissionImg from "@shared/assets/images/lettremission.png";
import missions from "@shared/assets/images/missions.png";
import collaborateur from "@shared/assets/images/collaborateur.png";
import solutionBanniere from "@shared/assets/images/solutionBanniere.jpg";

import iconSolution from "@shared/assets/images/iconSolution.png";
import iconProspect from "@shared/assets/images/iconProspect.png";
import iconBesoin from "@shared/assets/images/iconBesoin.png";
import iconLettreMission from "@shared/assets/images/iconLettreMission.png";
import iconCollaborateur from "@shared/assets/images/iconCollaborateur.png";
import iconMission from "@shared/assets/images/iconMission.png";
import iconPlay from "@shared/assets/icons/play.svg";
import dashbordIcon from "@shared/assets/icons/tbicon.svg";
import gestionMissionIcon from "@shared/assets/icons/gestionMission.svg";
import dataBaseIcon from "@shared/assets/icons/db.svg";
import prospectIcon from "@shared/assets/icons/prospectIcon.svg";
import teamsIcon from "@shared/assets/icons/teams.svg";

const PlanningAndSolution = () => {
  return (
    <div>
      <div id="avantages" className="styleBannierWhite">
        <h1>
          <p>Une bonne planification est le secret des cabinets</p>
          <p>qui réussissent leurs missions 5/5 !</p>
        </h1>
        <div className="secondeParagraphe">
          <p>
            Pas de fichier Excel, pas de fichier Word, toutes les données de vos
            missions sont regroupées dans une seule et même boîte à missions.
          </p>
          <p>
            Partout où vous êtes, et sur tous types de support, vous pouvez
            avoir une vue d’ensemble sur vos missions depuis la prospection
            jusqu’à la
          </p>
          <p>rédaction du rapport de fin de mission</p>
        </div>
      </div>
      <div className="contentCadrePlanning">
        <Grid container spacing={2}>
          <Grid item md={4}>
            <div className="cadrePlanning">
              <div className="headerCadrePlanning">
                <div className="cadreIcon">
                  <img src={iconSolution} alt="icon" />
                </div>
                <p className="textCadreIcon">La solution normative</p>
              </div>

              <div className="contentImgcadrePlanning">
                <img src={solutionImg} alt="solution" />
                <div className="overImgBanner cadre">
                  Vous avez des fiches modèles intégrées à la solution
                  (questionnaire de prospection, fiche d’acceptation client,
                  fiche d’acceptation mission et fiche de maintien client…).
                  Vous ne perdez plus de temps à refaire des fiches pour chacune
                  de vos missions. Servez-vous des modèles standards et
                  réadapter les à chacune de vos missions.
                </div>
              </div>
            </div>
          </Grid>
          <Grid item md={4}>
            <div className="cadrePlanning">
              <div className="headerCadrePlanning">
                <div className="cadreIcon">
                  <img src={iconProspect} alt="icon" />
                </div>
                <p className="textCadreIcon">
                  Une prospection fluide et efficace
                </p>
              </div>
              <div className="contentImgcadrePlanning">
                <img src={prospectImg} alt="prospect" />
                <div className="overImgBanner cadre">
                  Grâce à un espace dédié, intégrez la liste de vos prospects et
                  définissez facilement les prospects sur qui vous pouvez
                  effectuer des missions à forte valeur ajoutée.
                </div>
              </div>
            </div>
          </Grid>
          <Grid item md={4}>
            <div className="cadrePlanning">
              <div className="headerCadrePlanning">
                <div className="cadreIcon">
                  <img src={iconBesoin} alt="icon" />
                </div>
                <p className="textCadreIcon">
                  Les bons besoins définis, la bonne offre de mission proposée
                </p>
              </div>
              <div className="contentImgcadrePlanning">
                <img src={besoinsImg} alt="besoin" />
                <div className="overImgBanner cadre">
                  Définissez avec exactitude les besoins de vos prospects afin
                  de leur proposer des missions claires, complètes et
                  satisfaisantes.
                </div>
              </div>
            </div>
          </Grid>
          <Grid item md={4}>
            <div className="cadrePlanning">
              <div className="headerCadrePlanning">
                <div className="cadreIcon">
                  <img src={iconLettreMission} alt="icon" />
                </div>
                <p className="textCadreIcon">
                  Des lettres de missions bien évaluées pour votre cabinet
                </p>
              </div>
              <div className="contentImgcadrePlanning">
                <img src={lettremissionImg} alt="lettre" />
                <div className="overImgBanner cadre">
                  Etablissez vos lettres de missions en tenant compte de vos
                  ressources et de vos capacités internes. Récupérez
                  automatiquement les données nécessaires à la rédaction de
                  votre lettre de mission.
                </div>
              </div>
            </div>
          </Grid>
          <Grid item md={4}>
            <div className="cadrePlanning">
              <div className="headerCadrePlanning">
                <div className="cadreIcon">
                  <img src={iconCollaborateur} alt="icon" />
                </div>
                <p className="textCadreIcon">
                  Le bon collaborateur assigné à la bonne tâche, au bon coût et
                  au bon moment
                </p>
              </div>
              <div className="contentImgcadrePlanning">
                <img src={collaborateur} alt="collaborateur" />
                <div className="overImgBanner cadre">
                  Evaluez les ressources humaines et financières dont vous
                  disposez et affectez les tâches au bon profil collaborateur
                  tout en optimisant vos coûts internes.
                </div>
              </div>
            </div>
          </Grid>
          <Grid item md={4}>
            <div className="cadrePlanning">
              <div className="headerCadrePlanning">
                <div className="cadreIcon">
                  <img src={iconMission} alt="icon" />
                </div>
                <p className="textCadreIcon">
                  Suivi de mission efficace, délai respecté
                </p>
              </div>
              <div className="contentImgcadrePlanning">
                <img src={missions} alt="missions" />
                <div className="overImgBanner cadre">
                  Vous avez la possibilité de suivre les tâches, l’évolution et
                  la réalisation de celles-ci en automatisant la planification
                  de la mission dans le temps.
                </div>
              </div>
            </div>
          </Grid>
        </Grid>
        <div className="w-full h-auto text-center">
          <a
            style={{ textDecoration: "none" }}
            href={`${process.env.REACT_APP_CUSTOM_AKILCAB_URL}/#/landing`}
          >
            <button className="buttonLanding">Je m'abonne</button>
          </a>
        </div>
      </div>

      <div id="fonctionnalites" className="styleBannierWhite">
        <div className="cadreBannerSolution">
          <img src={solutionBanniere} alt="solutionIcon" />
          <div className="overImgBanner">
            <div className="cadrePlay">
              <img src={iconPlay} alt="play" />
            </div>
          </div>
        </div>
        <h1>
          <p>Une solution intuitive avec des fonctionnalités dynamiques,</p>
          <p> pour un cabinet productif et serein.</p>
        </h1>
        <div className="secondeParagraphe">
          <p>
            DIGITEXPERT est votre application de gestion qui vous aide à si bien
            organiser et planifier vos missions que vous ne dispersez pas vos
            ressources.
          </p>
          <p>
            Ses fonctionnalités conçues exprès pour vous, vous assurent le
            succès de toutes vos missions !
          </p>
        </div>
      </div>

      <Grid container spacing={1} className="content-grid">
        <Grid item md={4}>
          <div className="cadreGrid">
            <div className="cadreGridHeader">
              <span className="iconCadreGrid">
                <img src={dashbordIcon} alt="dashbord" />
              </span>
              <h3>Tableau de bord</h3>
            </div>

            <p className="cadreGridContent">
              Le tableau de bord vous permet d'avoir les indicateurs sur vos
              prospections, vos projets de mission, vos offres de mission, vos
              missions, vos clients et vos collaborateurs.
            </p>
          </div>
          <div className="cadreGrid">
            <div className="cadreGridHeader">
              <span className="iconCadreGrid">
                <img src={gestionMissionIcon} alt="gestion" />
              </span>
              <h3>Gestion mission</h3>
            </div>

            <p className="cadreGridContent">
              Suivez le processus d'exécution de toutes les missions de votre
              cabinet. Générez automatiquement les prévisions financières liées
              à la mission et déterminez-en un clic votre rentabilité.
            </p>
          </div>
        </Grid>
        <Grid item md={4} className="cadreGridColor">
          <div className="cadreGridFirst">
            <div className="cadreGridHeader">
              <span className="iconCadreGrid">
                <img src={dataBaseIcon} alt="data" />
              </span>
              <h3>Base de données</h3>
            </div>

            <p className="cadreGridContent">
              Établissez une base de données prospects, clients et enregistrez
              tous sur vos collaborateurs afin d'assigner et de suivre leurs
              tâches.
            </p>
          </div>
        </Grid>
        <Grid item md={4}>
          <div className="cadreGrid">
            <div className="cadreGridHeader">
              <span className="iconCadreGrid">
                <img src={prospectIcon} alt="prospection" />
              </span>
              <h3>Prospection</h3>
            </div>

            <p className="cadreGridContent">
              Identifiez les prospects, planifiez les actions, avoir les
              notifications des actions planifiées. Faire le suivi du prospect
              jusqu'à sa conversion client.
            </p>
          </div>
          <div className="cadreGrid">
            <div className="cadreGridHeader">
              <span className="iconCadreGrid">
                <img src={teamsIcon} alt="teams" />
              </span>
              <h3>Team management</h3>
            </div>

            <p className="cadreGridContent">
              Affectez les différentes tâches aux collaborateurs, et suivez
              l'évolution des tâches. Évaluez l'efficacité de vos collaborateurs
              et mesurez leur rapport coût et rentabilité.
            </p>
          </div>
        </Grid>
      </Grid>
      <div className="w-full h-auto text-center">
        <a
          style={{ textDecoration: "none" }}
          href={`${process.env.REACT_APP_CUSTOM_AKILCAB_URL}/#/landing`}
        >
          <button className="buttonLanding">Je m'abonne</button>
        </a>
      </div>
    </div>
  );
};

export default PlanningAndSolution;
