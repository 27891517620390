import { authRoles } from "app/auth";
import Landing from "./Landing";
import Faq from "./components/contents/Faq";
import Login from "./components/login/Login";

export const landingConfig = {
  settings: {
    layout: {
      config: {
        navbar: {
          display: false,
        },
        toolbar: {
          display: false,
        },
        footer: {
          display: false,
        },
        leftSidePanel: {
          display: false,
        },
        rightSidePanel: {
          display: false,
        },
      },
    },
  },
  auth: authRoles.onlyGuest,
  routes: [
    {
      path: "/landing",
      component: Landing,
    },
    {
      path: "/connexion",
      component: Login,
    },
    {
      path: "/faq",
      component: Faq,
    },
  ],
};
