import React from "react";
import horslineSvg from "@shared/assets/icons/horsline.svg";

const DisplayHorsLine = () => {
  const handlerealod = () => {
    window.location.reload();
  };
  return (
    <div className="container-horsline">
      <img width={200} height={200} src={horslineSvg} alt="hors line" />
      <h3>Problème de connexion internet !</h3>
      <button onClick={handlerealod} className="my-10">
        Actualiser
      </button>
    </div>
  );
};

export default DisplayHorsLine;
