import React from "react";
import { AppBar, Toolbar, Typography } from "@material-ui/core";
import { ThemeProvider } from "@material-ui/styles";
import { useSelector } from "react-redux";
import { version } from "app/environments/env";

function FooterLayout1(props) {
  const footerTheme = useSelector(({ fuse }) => fuse.settings.footerTheme);
  const currentYear = new Date().getFullYear();
  const style = {
    width: "78%",
    display: "flex",
    justifyContent: "flex-end",
    flex: "0 0 auto",
    zIndex: "3",
    marginLeft: "auto",
  };
  const footer = {
    background: "#fff",
  };

  return (
    <ThemeProvider theme={footerTheme}>
      <AppBar
        style={footer}
        id="fuse-footer"
        className="relative z-10"
        color="default"
      >
        <Toolbar className="px-16 py-0 flex items-center">
          <Typography variant="caption">
            Développé avec ♥ par{" "}
            <b>
              <a
                href="https://akiltechnologies.com"
                target="_blank"
                rel="noopener noreferrer"
              >
                AKIL TECHNOLOGIES
              </a>
            </b>
          </Typography>

          <Typography className="ml-700" style={style} variant="caption">
            DigitExpert
            {currentYear !== 2020 ? " 2020 - " + currentYear : " 2020 "} v.{" "}
            {version}
            {process.env.REACT_APP_CUSTOM_NODE_ENV === "prod"
              ? " "
              : "-" + process.env.REACT_APP_CUSTOM_NODE_ENV}
          </Typography>
        </Toolbar>
      </AppBar>
    </ThemeProvider>
  );
}

export default FooterLayout1;
