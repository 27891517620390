import { Grid } from "@material-ui/core";
import React from "react";
import dashbordIcon from "@shared/assets/icons/tbicon.svg";
import gestionMissionIcon from "@shared/assets/icons/gestionMission.svg";
import dataBaseIcon from "@shared/assets/icons/db.svg";
import prospectIcon from "@shared/assets/icons/prospectIcon.svg";
import teamsIcon from "@shared/assets/icons/teams.svg";

const Offer = () => {
  return (
    <div id="offre" className="content-offer">
      <h1 className="text-center">Offre</h1>
      <div className="cadreOffe">
        <Grid container spacing={1}>
          <Grid item md={6}>
            <div className="cadreOfferLeft">
              <h1>Découvrez notre offre faite sur mesure pour vous</h1>
              <div>
                <span>
                  <img src={dashbordIcon} alt="dashbord" />
                </span>
                <p>Tableau de bord</p>
              </div>
              <div>
                <span>
                  <img src={dataBaseIcon} alt="dataBase" />
                </span>
                <p>Base de données</p>
              </div>
              <div>
                <span>
                  <img src={prospectIcon} alt="prospection" />
                </span>
                <p>Prospection</p>
              </div>
              <div>
                <span>
                  <img src={gestionMissionIcon} alt="missions" />
                </span>
                <p>Gestion de missions</p>
              </div>
              <div>
                <span>
                  <img src={teamsIcon} alt="management" />
                </span>
                <p>Team management</p>
              </div>
            </div>
          </Grid>
          <Grid item md={6}>
            <div className="cadreOfferRight">
              <h1>Offre exclusive</h1>
              <div className="offerPrice">
                <h1>399.000 FCFA</h1>
                <span>Pour toute l'année</span>
              </div>
              <p>Toutes vos missions régroupées dans un seul et même espace.</p>
              <div className="w-full h-auto text-center pt-10">
                <a
                  style={{ textDecoration: "none" }}
                  href={`${process.env.REACT_APP_CUSTOM_AKILCAB_URL}/#/landing`}
                >
                  <button className="buttonLanding">Je m'abonne</button>
                </a>
              </div>
            </div>
          </Grid>
        </Grid>
      </div>
    </div>
  );
};

export default Offer;
