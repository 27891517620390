import React from "react";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
import { Grid } from "@material-ui/core";
import moussakamissoko from "@shared/assets/images/moussakamissoko.png";
import samake from "@shared/assets/images/samake.png";
import strataigeLogo from "@shared/assets/images/strataige-logo.png";
import neville from "@shared/assets/images/neville.jpeg";
import quoteIcon from "@shared/assets/icons/quote.svg";

const Testimonials = () => {
  function SampleNextArrow(props) {
    const { className, style, onClick } = props;
    return (
      <div
        className={className}
        style={{ ...style, display: "none" }}
        onClick={onClick}
      />
    );
  }
  function SamplePrevArrow(props) {
    const { className, style, onClick } = props;
    return (
      <div
        className={className}
        style={{ ...style, display: "none" }}
        onClick={onClick}
      />
    );
  }

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 2,
    autoplay: true,
    autoplaySpeed: 5000,
    pauseOnHover: true,
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />,
  };
  return (
    <div className="styleBannierWhite">
      <h1 className="py-10">Ce qu'ils disent de nous</h1>
      <div className="w-full h-full py-20 my-10">
        <Slider {...settings}>
          <Grid container className="mt-10">
            <Grid item md={12} className="testimonial-cadre">
              <div className="positionQuote">
                <img src={quoteIcon} alt="quote" />
              </div>
              <div>
                <p className="text-left">
                  Avant de faire la connaissance de la solution Akilcab au
                  congrès des experts-comptables qui s’est tenu à Cotonou
                  (Bénin) en 2019 nous avions un problème d’archivage de nos
                  données. Et nous dépensions énormément d’argent pour
                  l’assistance de nos clients dans les autres villes.
                  Aujourd’hui la distance n’est plus un frein à notre
                  management. Akilcab nous a rendu tout simplement la vie facile
                  !
                </p>
                <div className="flex justify-start items-center">
                  <div className="TestimonialCadreImg">
                    <img src={moussakamissoko} alt="olga" />
                  </div>
                  <h3>Mahamadou Moussa KAMISSOKO</h3>
                </div>
              </div>
            </Grid>
          </Grid>
          <Grid container className="mt-10">
            <Grid item md={12} className="testimonial-cadre">
              <div className="positionQuote">
                <img src={quoteIcon} alt="quote" />
              </div>
              <div>
                <p className="text-left">
                  A l’arrêté des comptes 2018 nous avons réalisé que la
                  plateforme Akilcab résolvait nos problèmes de gestion, car ces
                  périodes étaient auparavant très stressantes. Sans oublier que
                  l’établissement des états financiers demandait un temps de
                  travail trop long, sans toutefois garantir l'absence d'erreur
                  arithmétique, et une concordance parfaite entre les différents
                  documents. Aujourd’hui, nous sommes assez satisfaits parce que
                  la supervision et la révision des travaux sont devenues plus
                  simples.
                </p>
                <div className="flex justify-start items-center">
                  <div className="TestimonialCadreImg">
                    <img src={strataigeLogo} alt="olga" />
                  </div>
                  <h3>ANDRET DIET</h3>
                </div>
              </div>
            </Grid>
          </Grid>
          <Grid container className="mt-10">
            <Grid item md={12} className="testimonial-cadre">
              <div className="positionQuote">
                <img src={quoteIcon} alt="quote" />
              </div>
              <div>
                <p className="text-left">
                  Dès notre première utilisation de Akilcab, nous l’avons
                  validé. Cette plateforme de gestion en ligne supprime le
                  sentiment d’insatisfaction que nous éprouvions auparavant.
                </p>
                <div className="flex justify-start items-center">
                  <div className="TestimonialCadreImg">
                    <img src={samake} alt="olga" />
                  </div>
                  <h3>Massaoulé SAMAKE</h3>
                </div>
              </div>
            </Grid>
          </Grid>
          <Grid container className="mt-10">
            <Grid item md={12} className="testimonial-cadre">
              <div className="positionQuote">
                <img src={quoteIcon} alt="quote" />
              </div>
              <div>
                <p className="text-left">
                  L’application Digit Expert a su capturer le métier complexe
                  des cabinets de gestion en leur proposant une offre unique de
                  gestion budgétaire et de planification. En tant que
                  responsable d’équipes, je suis sensible à la performance de
                  mes collaborateurs mais également à la rentabilité des
                  missions. J’aime la complétude de Digit Expert.
                </p>
                <div className="flex justify-start items-center">
                  <div className="TestimonialCadreImg">
                    <img src={neville} alt="olga" />
                  </div>
                  <h3>Neville BONI</h3>
                </div>
              </div>
            </Grid>
          </Grid>
        </Slider>
      </div>
    </div>
  );
};

export default Testimonials;
