import React from "react";

export const CabinetDashboardAppConfig = {
  settings: {
    layout: {
      config: {},
    },
  },
  routes: [
    {
      path: "/cabinet/dashboard",
      component: React.lazy(() => import("./CabinetDashboardApp")),
    },
  ],
};
