import React from "react";
import BannerOfPresentation from "./contents/BannerOfPresentation";
import ContactsLanding from "./contents/ContactsLanding";
import Offer from "./contents/Offer";
import PlanningAndSolution from "./contents/PlanningAndSolution";
import Testimonials from "./contents/Testimonials";

const ContentLanding = () => {
  return (
    <div className="styleContentLanding">
      <BannerOfPresentation />
      <PlanningAndSolution />
      <Offer />
      <Testimonials />
      <ContactsLanding />
    </div>
  );
};

export default ContentLanding;
