import React from "react";
import Footer from "./components/contents/Footer";
import NavLanding from "./components/NavbarLanding";

const ContentPage = ({ children }) => {
  return (
    <div className="content-landing">
      <NavLanding />
      {children}
      <Footer />
    </div>
  );
};

export default ContentPage;
