const digitExpertLight = {
  50: "#fdf1eb",
  100: "#fbdccc",
  200: "#f8c4aa",
  300: "#f5ac88",
  400: "#f29b6f",
  500: "#f08955",
  600: "#eb7a4a",
  700: "#e3663c",
  800: "#dd532f",
  900: "#d13118",
  A100: "#fcae86",
  A200: "#fa864b",
  A400: "#f95d0f",
  A700: "#d14a05",
  // contrast: (
  //   50: $dark-primary-text,
  //   100: $dark-primary-text,
  //   200: $dark-primary-text,
  //   300: $dark-primary-text,
  //   400: $dark-primary-text,
  //   500: $dark-primary-text,
  //   600: $dark-primary-text,
  //   700: $light-primary-text,
  //   800: $light-primary-text,
  //   900: $light-primary-text,
  //   A100: $dark-primary-text,
  //   A200: $dark-primary-text,
  //   A400: $light-primary-text,
  //   A700: $light-primary-text
  // )
};

export default digitExpertLight;
